import "./Leaderboard.css";
import ProfListLeaderboard from "./ProfListLeaderboard.js";
import { motion } from "framer-motion";
import { useState } from "react";



const Leaderboard = ({}) => {



  const [whichSlide, setSlide] = useState(0)

  // Switching Leaderboard Slides
  const switchUnder = (e) => {
    const headList = [document.getElementsByClassName("navItem1LB"),document.getElementsByClassName("navItem2LB"),document.getElementsByClassName("navItem3LB")]
    headList.map((e, index) => {
      (headList[index][0]).style.borderBottom = "none";
    })
    e.target.style.borderBottom = ".2vw solid black";
    setSlide(e.target.id)
  }

  

  return (
    <motion.div
    className="LeaderboardDesktop">


        <div className="leaderboardBody">
        <div className="navHeadLeaderboard">
          <div className="navItem1LB" id="0" onClick={switchUnder}style={{borderBottom: ".2vw solid black"}}>Time</div>

          <div className="navItem2LB" id="1" onClick={switchUnder}>Quiz Avg</div>

          <div className="navItem3LB" id="2" onClick={switchUnder}>GamePrep Score</div>
        </div>

        {whichSlide == 0 && <ProfListLeaderboard  pic="prof" order={[2,3,1]} scores={["3:28", "8:19", "5:15"]} name="Christian Shimko"/>}
        {whichSlide == 1 && <ProfListLeaderboard  pic="griff" order={[3,2,1]} scores={["77%","89%","94%"]} name="Griffin Speaks"/>}
        {whichSlide == 2 && <ProfListLeaderboard  pic="ben" order={[2,3,1]} scores={["78", "93", "64"]} name="Ben Hamilton"/>}

        </div>
    </motion.div>
  );
}

export default Leaderboard;
