import "./profList.css";
import { React} from "react";
import { motion } from "framer-motion";
import prof from "../Profiles/prof.webp";
import griff from "../Profiles/griff.jpeg";
import ben from "../Profiles/ben.jpeg";
import coach from "../Profiles/male1.jpeg";
import ProfDiv from "./profDiv";


const ProfListC = (props) => {
  

  const switchPic = (picName) => {
    switch (picName) {
      case "prof":
        return prof;
        break;
      case "griff":
        return griff;
        break;
      case "ben":
        return ben;
        break;
    }
  };

  const pics = [prof, ben, griff, coach];
  const names = ["Christian Shimko", "Ben Hamilton", "Griffin Speaks"];
  const pos = ["Wide Receiver #13", "Defensive Back #53", "Safety #39"];
  const scores = props.scores;

  const pic = switchPic(props.pic);

  const orderone = props.order;

  // Simulate the external database value (replace with actual data fetching)
  const externalDatabaseValue = 3;

  // Create an array of numbers based on the external value
  const divCount = Array.from(
    { length: externalDatabaseValue },
    (_, index) => index + 1
  );

  const dropIn = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="profList2"
      variants={dropIn}
      initial="hidden"
      animate="visible"
    >
      {orderone.map((index, value) => (
        <ProfDiv setDel={props.setDel} index={index} tfd={props.tfd} tf={props.tf} />
      ))}

      
    </motion.div>
  );
};

export default ProfListC;
