import HomeSwitch from "./HomePanels/RightPanel/HomeSwitch.js";
import "./HomeMain.css";
import HomeLeftCol from "./HomePanels/LeftPanel/HomeLeftCol.js";

// Main home component holding a flexbox of the left (Notifications) and right (Roster, Leaderboard, Recommended) sections
const HomeMain = ({
  groupPlays,
    currentQuizzes,
    name,
    pic,
    setOpenProf,
    currQuizClicked,
    playsData,
    SCP,
    favoritePlays,
    players, coaches, setCoaches, setPlayers, profile

}) => {

  return (
    <div className="overallSPAH">
      <div className="innerSPA">
        <HomeLeftCol
          setOpenProf={setOpenProf}
          pic={pic}
          name={name}
          currentQuizzes={currentQuizzes}
          currQuizClicked={currQuizClicked}
          playsData={playsData}
          groupPlays={groupPlays}
          SCP={SCP}
          
        />
      </div>
      <HomeSwitch
        SCP={SCP}
        playsData={playsData}
        favoritePlays={favoritePlays}
        players={players} profile={profile} coaches={coaches} setCoaches={setCoaches} setPlayers={setPlayers}
      />
    </div>
  );
};

export default HomeMain;
