
import { motion } from "framer-motion";
import "./QuestionReviewC.css";

const QuestionReview = ({
  handleClose,
  correctIndex,
  userIndex,
  tit,
  pic,
  aList,
}) => {
  const QP = require(`${pic}`);

  const borderList = ["#CACACA", "#CACACA", "#CACACA", "#CACACA"];

  if (userIndex === correctIndex) {
    borderList[userIndex] = "green";
  } else {
    borderList[userIndex] = "red";
    borderList[correctIndex] = "green";
  }

  function textSize(txt) {
    if (txt.length > 130) {
      return "xsText";
    } else if (txt.length > 115) {
      return "sText";
    } else if (txt.length > 80) {
      return "mText";
    } else if (txt.length > 50) {
      return "lText";
    } else {
      return "xlText";
    }
  }

  function textSizeAnswer(txt) {
    if (txt.length > 50) {
      return "sTextA";
    } else if (txt.length > 40) {
      return "mTextA";
    } else {
      return "lTextA";
    }
  }

  const dropIn = {
    hidden: {
      x: "100vw",
      opacity: 1,
    },
    visible: {
      x: "0vw",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 30,
        stiffness: 300,
      },
    },
    exit: {
      x: "100vw",
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="fullQuestion"
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div onClick={handleClose} className="back-containerResult">
        <svg
          width="2vw"
          height="5.1vw"
          className="triBack"
          viewBox="0 0 99 109"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z" fill="black" />
        </svg>
        <div className="backTxtResult">Back</div>
      </div>
      <div className="QuestionReview">
        <div className="PnQ">
          <div className="inP">
            <div className="questionText" id={textSize(tit)}>
              {tit}
            </div>
          </div>
          <div className="inP">
            <div className="playPicCap">
              <img src={QP} className="playPic"></img>
            </div>
          </div>
        </div>

        <div className="answers2">
          {borderList.map((item, index) => {
            if (item === "green") {
              return (
                <div
                  className="unAnswerResult2"
                  style={{ borderColor: "green" }}
                >
                  <div className="innerA" id={textSizeAnswer(aList[index])}>
                    {aList[index]}
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="PlayersHeadtxt">Player Stats:</div>
        <div className="oFlexPlayers">
          <div className="iFlexPlayers">
            <div className="iiFlexPlayer">
            <svg
                width="5vw"
                height=""
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>
              <div className="playerResTxt">Ben Hamilton</div>
            </div>
            <div className="iiFlexPlayer">
            <svg
                width="5vw"
                height=""
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>

              <div className="playerResTxt">Griffin Speaks</div>
            </div>
          </div>
          <div className="iFlexPlayers">
            <div className="iiFlexPlayer">
            <svg
                width="4vw"
                height=""
                viewBox="0 0 68 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.10938 64.1841L63.1843 4.10913"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M4.52344 3.40186L64.5984 63.4768"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>
              <div className="playerResTxt">Ben Hamilton</div>
            </div>
            <div className="iiFlexPlayer">
            <svg
                width="5vw"
                height=""
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>

              <div className="playerResTxt">Griffin Speaks</div>
            </div>
          </div>
          <div className="iFlexPlayers">
            <div className="iiFlexPlayer">
              <svg
                width="5vw"
                height=""
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>
              <div className="playerResTxt">Griffin Speaks</div>
            </div>
            <div className="iiFlexPlayer">
              <svg
                width="4vw"
                height=""
                viewBox="0 0 68 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.10938 64.1841L63.1843 4.10913"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M4.52344 3.40186L64.5984 63.4768"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>

              <div className="playerResTxt">Griffin Speaks</div>
            </div>
          </div>
          <div className="iFlexPlayers">
            <div className="iiFlexPlayer">
              <svg
                width="5vw"
                height=""
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>
              <div className="playerResTxt">Griffin Speaks</div>
            </div>
            <div className="iiFlexPlayer">
              <svg
                width="4vw"
                height=""
                viewBox="0 0 68 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.10938 64.1841L63.1843 4.10913"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M4.52344 3.40186L64.5984 63.4768"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>

              <div className="playerResTxt">Ben Hamilton</div>
            </div>
          </div>
          <div className="iFlexPlayers">
            <div className="iiFlexPlayer">
              <svg
                width="5vw"
                height=""
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>
              <div className="playerResTxt">Griffin Speaks</div>
            </div>
            <div className="iiFlexPlayer">
            <svg
                width="5vw"
                height=""
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>

              <div className="playerResTxt">Ben Hamilton</div>
            </div>
          </div>
          <div className="iFlexPlayers">
            <div className="iiFlexPlayer">
              <svg
                width="4vw"
                height=""
                viewBox="0 0 68 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.10938 64.1841L63.1843 4.10913"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M4.52344 3.40186L64.5984 63.4768"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>

              <div className="playerResTxt">Griffin Speaks</div>
            </div>
            <div className="iiFlexPlayer">
              <svg
                width="5vw"
                height=""
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>

              <div className="playerResTxt">Griffin Speaks</div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default QuestionReview;
