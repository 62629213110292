import "./PlayViewD.css";
import { motion, AnimatePresence } from "framer-motion";
import face from "../../Profiles/unknownProfile.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { useEffect, useRef, useState } from "react";

const PlayViewD = ({
  close,
  plays,
  handleDeletePlay,
  setFavoritePlays,
  favoritePlays
}) => {


  const [isFav, setisFav] = useState(favoritePlays.includes(plays[0].id));

  const [del, setDel] = useState(false);

  const [currIndex, setCurrIndex] = useState(0);
  const [currPlay, setCurrPlay] = useState(plays[0]);


  const [title, setTitle] = useState(plays[0].title);
  const [desc, setDesc] = useState(plays[0].caption);
  const [parent, setParent] = useState(plays[0].parentFolder);
  const [IMGS, setIMGS] = useState(()=> {

    const Images = [];
    plays[0].pic.map((i) => {
      if(plays[0].id > 16){
        Images.push(i);
      } else {
        const pic = require(`${i}`);
        Images.push(pic);
      }
      
    });
    return Images;
    
  });
  const swipRef = useRef(null);

  useEffect(()=>{
    setTitle(currPlay.title);
    setDesc(currPlay.caption);
    setParent(currPlay.parentFolder);
    setisFav(favoritePlays.includes(currPlay.id));
    setIMGS(()=> {

      const imag = [];
      currPlay.pic.map((i) => {
        if(currPlay.id > 16){
          imag.push(i);
        } else {
          const pic = require(`${i}`);
          imag.push(pic);
        }
        
      });
      return imag;
      
    });


  }, [currPlay])

  useEffect(()=>{
    setCurrPlay(plays[currIndex])
  }, [currIndex])

  function BF (RL) {
    if (RL>0){
      if (currIndex < plays.length-1){
        setCurrIndex(currIndex+1)
      }
    }
    else {
      if (currIndex > 0){
        setCurrIndex(currIndex-1)
      }
      
    }
    
  }

  function setFavList (){
    if(isFav){
      setFavoritePlays((prev) => prev.filter((playId) => playId !== currPlay.id));
    }
    else{
      setFavoritePlays((prev) => [...prev, currPlay.id]);
    }
    
  }

  useEffect(()=> {
    setisFav(favoritePlays.includes(currPlay.id));
  },[favoritePlays])


  function nav (n) {

    if (n>0){
      if (swipRef.current) {
      swipRef.current.swiper.slideNext();
    }
    }
    else {
      if (swipRef.current) {
      swipRef.current.swiper.slidePrev();
    }
    }

  }

  function close2 () {
    handleDeletePlay(currPlay.id);
    close();

  }




  return (
    <div>
      <motion.div className="fullProfDD"></motion.div>
      
      <div className="topBar">
      <div onClick={close} className="back-containerPV">
        <svg
          width="1.7vh"
          height="1.7vh"
          className="triBackD"
          viewBox="0 0 99 109"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z" fill="white" />
        </svg>
        <div className="backTxtProfD">Back</div>
      </div>
        <div className="innerFlexTopBar">
            <div className="topBarTitle">{title}</div>
            <div className="topBarFolder">{parent}</div>
        </div>
        <div className="flexColumnD">
          <motion.div
            className="boxD"
            whileTap={{ scale: 0.9 }}
            style={isFav ? {backgroundColor: "#0072E2" } : {backgroundColor: "rgb(255, 255, 255)" }}
            onClick={setFavList}
          >
            <svg
              width="2.3vh"
              height="2.3vh"
              className="star"
              viewBox="0 0 20 18"
              fill="none"
            >
              <path
                d="M10 1L7.5 7H1L6.5 11L4.5 17.5L10 13.5L15.5 17.5L13.5 11L19 7H12.5L10 1Z"
                fill="black"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </motion.div>
          <motion.div
            className="boxD"
            whileTap={{ scale: 0.9 }}
            onClick={()=> {setDel(true)}}
          >
            <svg
              width="2.3vh"
              height=""
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.03125 2.23438H4.875C4.96094 2.23438 5.03125 2.17109 5.03125 2.09375V2.23438ZM5.03125 2.23438H10.9688V2.09375C10.9688 2.17109 11.0391 2.23438 11.125 2.23438H10.9688V3.5H12.375V2.09375C12.375 1.47324 11.8145 0.96875 11.125 0.96875H4.875C4.18555 0.96875 3.625 1.47324 3.625 2.09375V3.5H5.03125V2.23438ZM14.875 3.5H1.125C0.779297 3.5 0.5 3.75137 0.5 4.0625V4.625C0.5 4.70234 0.570312 4.76562 0.65625 4.76562H1.83594L2.31836 13.959C2.34961 14.5584 2.90039 15.0312 3.56641 15.0312H12.4336C13.1016 15.0312 13.6504 14.5602 13.6816 13.959L14.1641 4.76562H15.3438C15.4297 4.76562 15.5 4.70234 15.5 4.625V4.0625C15.5 3.75137 15.2207 3.5 14.875 3.5ZM12.2832 13.7656H3.7168L3.24414 4.76562H12.7559L12.2832 13.7656Z"
                fill="black"
              />
            </svg>
          </motion.div>
        </div>

      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        className="customSwiper"
        ref={swipRef}
      >
        {IMGS.map((image, index) => (
          <SwiperSlide className="playPicInner" key={index} >
            <img src={image} className="playPic2D"  alt={`Slide ${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="navFlex"> 
      <svg
              width="3vw"
              height="3vw"
              viewBox="0 0 11 19"
              fill="none"
              onClick={()=>nav(0)}
              style={{
                zIndex:"1000000000000000000000000000"
                
              }}
            >
              <path
                d="M9.5 1L1 9.5L9.5 18"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              width="3vw"
              height="3vw"
              viewBox="0 0 11 19"
              fill="none"
              onClick={()=>nav(1)}
              style={{
                transform: "rotate(-180deg)",
                zIndex:"1000000000000000000000000000"
              }}
            >
              <path
                d="M9.5 1L1 9.5L9.5 18"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          

      </div>

      {
        plays.length>1 && <div className="PlayNavFlex"> 
        <motion.div onClick={()=>BF(0)} className="playNavBox" whileTap={{ scale: 0.95 }}>
                Prev play
        </motion.div>
        <motion.div  onClick={()=>BF(1)} className="playNavBox" whileTap={{ scale: 0.95 }}>
                Next play
        </motion.div>
        </div>
      }
      
      

        <motion.div
        className="commentsModalD"
      >
        <div className="commentTopFlexD">
          <svg
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            width="2vw"
            height="2vw"
          >
            <path
              fill="currentColor"
              d="M904 512h-56c-4.4 0-8 3.6-8 8v320H184V184h320c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V520c0-4.4-3.6-8-8-8"
            ></path>
            <path
              fill="currentColor"
              d="M355.9 534.9L354 653.8c-.1 8.9 7.1 16.2 16 16.2h.4l118-2.9c2-.1 4-.9 5.4-2.3l415.9-415c3.1-3.1 3.1-8.2 0-11.3L785.4 114.3c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-415.8 415a8.3 8.3 0 0 0-2.3 5.6m63.5 23.6L779.7 199l45.2 45.1l-360.5 359.7l-45.7 1.1z"
            ></path>
          </svg>
          
        </div>

        <div className="commentsTextD">
          <div className="coachCommentBoxD">
            <div className="coachCommentTextD">
              Make sure the mike and will give a “strong right” or “strong left”
              call before the snap. Keep your eyes on the open man on the line
            </div>
            <div className="coachCommentPicCapD">
              {" "}
              <img src={face} className="coachPlayPicD" />
            </div>
          </div>

          <div className="pCommentsAreaD">

          </div>
        </div>

        

      </motion.div>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {del && (
          <div onClick={()=> setDel(false)} className="blackBack">
            <div className="delBox">
              <h className="delBoxHead">Delete play</h>
              <div>Are you sure you want to delete this play? This action can not be undone.</div>
              <motion.div whileTap={{scale: 0.9}} onClick={close2}   className="DPdel">Delete</motion.div>
            </div>

          </div>
        )}
      </AnimatePresence>

    </div>
  );
};

export default PlayViewD;
