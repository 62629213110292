import { useState, useEffect } from "react";
import "./HomeLeftCol.css";
import { motion } from "framer-motion";
import PlayNot from "./PlayNot.js";
import QuizNot from "./QuizNot.js";
import face from "../../../Profiles/unknownProfile.jpg"

// Notifications section of home
const HomeLeftCol = ({
  setOpenProf,
  name,
  currentQuizzes,
  currQuizClicked,
  playsData,
  groupPlays,
  SCP
}) => {





  // List of play notifications
  const [playList, setPlaylist] = useState(()=> {
    let newPlayList = [];
    for (const [id, data] of Object.entries(playsData)) {
      if (groupPlays.includes(id)){
        newPlayList.push(data);
      }
    }
    return newPlayList;
  })


  // List of quiz notifications
  const [QuizList, setQuizList] = useState(
    currentQuizzes.map((item, index) => {
      return (
        <QuizNot
          quiz={item}
          idd={item.id}
          quizName={item.name}
          sub={item.subtitle}
          time={`12/25 Submitted`}
          currQuizClicked={currQuizClicked}
        />
      );
    })
  );

  useEffect(() => {
    const updatedQuizList = currentQuizzes.map((item, index) => (
      <QuizNot
        quiz={item}
        idd={item.id}
        quizName={item.name}
        sub={item.subtitle}
        time={`12/25 Submitted`}
        currQuizClicked={currQuizClicked}
      />
    ));

    setQuizList(updatedQuizList);
  }, [currentQuizzes]);

  return (
    <div className="HomeLeftCol">
      {/*Profile Pic*/}
      <motion.div
        className="profPicDesktop"
        onClick={setOpenProf}
        whileTap={{ scale: 0.9 }}
      >
        <img src={face} style={{width: "100%"}} />
      </motion.div>
      {/* header */}
      <div className="greetingDesktop">Welcome Back, {name}.</div>


      {/* Quiz Notifications */}
      <div className="QuizNotificationsHome">
        <div className="QuizNotificationsHomeHead">Current Quizzes</div>
        <div className="QuizNotificationsHomeCap">{QuizList}</div>
      </div>

      {/* Play Notifications */}
      <div className="PlayNotificationsHome">
        <div className="PlayNotificationsHomeHead">Notifications</div>
        <div className="PlayNotificationsHomeCap">
          {/* Map each play to a component */}
          {playList.map((item, index) => {
            return (
              <PlayNot SCP={SCP} play={item} duration={`${index * 2 - 1 + 4} hours`} />
            );
          })}
        </div>
      </div>

    </div>
  );
};

export default HomeLeftCol;
