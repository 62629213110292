import {motion} from "framer-motion";
import "./drop.css"

const Backdrop = ({children, onClick}) => {
    const fadeIn = {
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 0.8,
          transition: {
            duration: 0.5,
            type: "spring",
            damping: 30,
            stiffness: 300,
          },
        },
        exit: {
          opacity: 0,
        },
      };

    return (
        <motion.div
        className="backdrop"
        onClick={onClick}
        variants={fadeIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        >
            {children}
        </motion.div>
    )

};

export default Backdrop;