import { useState } from "react";
import "./QuizHome.css";
import QuizHomeLeft from "./QuizHomeLeftCol.js";
import QuizResult from "./QuizResultDesktop.js";

const QuizHome = ({
  setCreate,
  currentQuizzes,
  pastQuizzes,
  currQuizClicked,
  seeByClicked,
}) => {

  const [currPastQuizIndex, setCurrPastQuizIndex] = useState(0);
  const [currPastQuiz, setCurrPastQuiz] = useState(pastQuizzes[0]);


  
  function clickPastQuiz (index) {
    setCurrPastQuizIndex(index)
    setCurrPastQuiz(pastQuizzes[index]);
  }

  return (
    <div className="QuizSPA">
        <QuizHomeLeft
          setCreate={setCreate}
          currentQuizzes={currentQuizzes}
          pastQuizzes={pastQuizzes}
          openCurrQuiz={currQuizClicked}
          clickPastQuiz={clickPastQuiz}
        />

      <QuizResult seeByClicked={seeByClicked} currPastQuiz={currPastQuiz} currPastQuizIndex={currPastQuizIndex} />
    </div>
  );
};

export default QuizHome;
