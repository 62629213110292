import {useEffect, useRef } from "react";
import { motion} from "framer-motion";
import "./PlayC.css";
import m1 from "../Profiles/male1.jpeg";
import m2 from "../Profiles/male2.jpeg";
import m3 from "../Profiles/male3.jpeg";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const Play = ({
  setCap,
  currID,
  setCoachPlays,
  coachPlays,
  currentPlays,
  setCurrentPlays,
  play,
  setPic,
  isStarr,
}) => {

  

  const images = [];
  let coachFace = m1;

  const divRef = useRef(null);

  let isFav = isStarr;

  play.pic.map((i) => {
    const pic = require(`${i}`);
    images.push(pic);
  });

  switch (play.coach) {
    case "Coach Smith":
      coachFace = m3;
      break;

    case "Coach Brown":
      coachFace = m1;
      break;

    case "Coach Johnson":
      coachFace = m2;
      break;

    default:
  }

  useEffect(()=>{
    console.log(currentPlays);
  },[currentPlays]);

  function delPlay(id){
    let newFavs = currentPlays;
      const index = newFavs.indexOf(id);
      const x = newFavs.splice(index, 1);
    
    setCurrentPlays(newFavs);
  
  }

  function setfav(i) {
    // if (isFav) {
    //   let newFavs = coachPlays;

    //   const index = newFavs.indexOf(i);

    //   const x = newFavs.splice(index, 1);

    //   setCoachPlays(newFavs);

    //   divRef.current.style.backgroundColor = "rgb(255, 255, 255)";

    //   isFav = false;
    // } else {
    //   let newFavs = coachPlays;

    //   newFavs.push(i);

    //   setCoachPlays(newFavs);

    //   divRef.current.style.backgroundColor = "rgb(0, 114, 226)";
    //   isFav = true;
    // }
  }


  return (
    <div className="playCap">
      <div className="PlayTitleFlex">
        <div className="playNameHead">{play.title}</div>
        <div className="playFolderHead">{play.parentFolder}</div>
      </div>

      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={"10vw"}
        slidesPerView={1}
        pagination={{ clickable: true }}
        onSlideChange={() => console.log("slide change")}
        style={{ width: "90vw", position: "relative", marginTop: "4vw" }}
      >
        {images.map((img, index) => (
          <SwiperSlide
            style={{
              paddingBottom: "10vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vw",
            }}
          >
            <img
              src={img}
              onClick={() => setPic(img)}
              className="playPic2"
            ></img>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="BottomFlex">
        <motion.div
          className="captionBox"
          whileTap={{ scale: 0.9 }}
        >
          <div className="coachPicPlayCap">
            <img src={coachFace} className="coachPlayPic" />
          </div>
          <div className="captionBoxTopFlex">
            <div className="captionName">{play.coach}</div>
            <div className="captionDate">2 Weeks ago</div>
          </div>
          <div className="captionText">{play.caption}</div>
        </motion.div>

        <div className="flexColumn">
          <motion.div
            className="box"
            onClick={() => setfav(currID)}
            ref={divRef}
            style={
              isFav
                ? { backgroundColor: "rgb(0, 114, 226)" }
                : { backgroundColor: "rgb(255, 255, 255)" }
            }
          >
            <svg
              width="5vw"
              height="5vw"
              className="star"
              viewBox="0 0 20 18"
              fill="none"
            >
              <path
                d="M10 1L7.5 7H1L6.5 11L4.5 17.5L10 13.5L15.5 17.5L13.5 11L19 7H12.5L10 1Z"
                fill="black"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </motion.div>
          <motion.div
            className="box"
            onClick={()=>delPlay(currID)}
            whileTap={{ scale: 0.9 }}
          >
            <svg
              width="5vw"
              height=""
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.03125 2.23438H4.875C4.96094 2.23438 5.03125 2.17109 5.03125 2.09375V2.23438ZM5.03125 2.23438H10.9688V2.09375C10.9688 2.17109 11.0391 2.23438 11.125 2.23438H10.9688V3.5H12.375V2.09375C12.375 1.47324 11.8145 0.96875 11.125 0.96875H4.875C4.18555 0.96875 3.625 1.47324 3.625 2.09375V3.5H5.03125V2.23438ZM14.875 3.5H1.125C0.779297 3.5 0.5 3.75137 0.5 4.0625V4.625C0.5 4.70234 0.570312 4.76562 0.65625 4.76562H1.83594L2.31836 13.959C2.34961 14.5584 2.90039 15.0312 3.56641 15.0312H12.4336C13.1016 15.0312 13.6504 14.5602 13.6816 13.959L14.1641 4.76562H15.3438C15.4297 4.76562 15.5 4.70234 15.5 4.625V4.0625C15.5 3.75137 15.2207 3.5 14.875 3.5ZM12.2832 13.7656H3.7168L3.24414 4.76562H12.7559L12.2832 13.7656Z"
                fill="black"
              />
            </svg>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Play;
