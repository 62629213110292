import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import "./QuizHome/QuizResultDesktop.css";

import QuizHome from "./QuizHome/QuizHome.js";
import CreateQuiz from "./CAQ/CreateQuiz.js";

const QuizOver = ({
  currQuizClicked,
  seeByClicked,
  pastQuizzes,
  playsData,
  SCP,
  groupPlays,
  playTitles,
  newQuiz,
  currentQuizzes,
}) => {
  
  //state to switch between create a quiz (CAQ) and Quiz Home
  const [create, setCreate] = useState(false);



  //open calendar
  const [openCal, setOpenCal] = useState(false);

  //On selecting date handler for calendar
  const [dueDate, setDueDate] = useState(null);

  const onDateClick = (arg) => {
    setDueDate(arg.dateStr);
    setOpenCal(false);
    return ((arg.date.getMonth() + 1) + "/" + arg.date.getDate() + "/" + arg.date.getFullYear().toString().substr(2, 2));
  };

  return (
    <div>
      {create && (
        <CreateQuiz
          playsData={playsData}
          SCP={SCP}
          groupPlays={groupPlays}
          playTitles={playTitles}
          dueDate={dueDate}
          setDueDate={setDueDate}
          currentQuizzes={currentQuizzes}
          setCreate={setCreate}
          newQuiz={newQuiz}
          setOpenCal={setOpenCal}
        />
      )}
      {!create && (
        <QuizHome
          setCreate={setCreate}
          currentQuizzes={currentQuizzes}
          pastQuizzes={pastQuizzes}
          currQuizClicked={currQuizClicked}
          seeByClicked={seeByClicked}
        />
      )}

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openCal && (
          <div className="blackBack">
            <div className="calCapD">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                weekends={false}
                dateClick={onDateClick}
              />
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default QuizOver;
