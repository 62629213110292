import "./ProfDivGroup.css";
import { React, useEffect, useState } from "react";
import face from "../../../Profiles/unknownProfile.jpg";


const ProfDivGroup = ({ index, tf }) => {
  const [isBlue, setIsBlue] = useState(false);
  

  useEffect(() => {
  }, [isBlue]);

  const names = ["Christian Shimko", "Ben Hamilton", "Griffin Speaks"];
  const pos = ["Wide Receiver #13", "Defensive Back #53", "Safety #39"];



  return (
    <div className="ProfDivGroupD">
      <div className="profPicGroupD">
        <img src={face} className="picGroupD" />
      </div>
      <div className="nameGroupOuterD">
        <div>
          <div className="nameHeadGroupD">{names[index - 1]}</div>
          <div className="nameSubGroupD">{pos[index - 1]}</div>
        </div>
      </div>

      {!tf && (
        <div
          onClick={() => setIsBlue(true)}
          style={
            isBlue
              ? { backgroundColor: "#0072E2" }
              : { backgroundColor: "transparent" }
          }
          className="hollowDotGroup"
        ></div>
      )}
      


    </div>
  );
};

export default ProfDivGroup;
