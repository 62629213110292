import "./Profile.css";
import { motion } from "framer-motion";
import face from "../../Profiles/unknownProfile.jpg"


const Profile = ({ close }) => {
  return (
    <div onClick={()=>close(false)} className="blackBackProfile">
      <div className="ProfileBox" onClick={(e) => e.stopPropagation()}>
        <div className="ProfilePicModal"><img style={{width:"100%"}} src={face}></img></div>
        <h className="ProfileBoxHead">Coach Smith</h>
        <div className="subTextProfile">Offensive Coordinator</div>
        <motion.div
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            window.location.reload();
          }}
          className="DPProfile"
        >
          Sign Out
        </motion.div>
      </div>
    </div>
  );
};

export default Profile;
