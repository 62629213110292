import "./ProfList.css";
import { React } from "react";
import face from "../../../../Profiles/unknownProfile.jpg";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Individual profile components in profile list
const ProfDiv = ({ indexToRemove, profile, PlayerOrCoach, setCoaches, setPlayers  }) => {

  const [del, setDel] = useState(false);

  function delProfile(){
    if (PlayerOrCoach){
      setPlayers((prevPlayers) => prevPlayers.filter((_, index) => index !== indexToRemove));
    }
    else {
      setCoaches((prevCoaches) => prevCoaches.filter((_, index) => index !== indexToRemove));
    }
    setDel(false);
  }

  return (
    <div className="divItemProfList">
      <div className="profPicProfList">
        <img src={face} className="picProfList" />
      </div>
      <div className="nameProfList">
        <div className="">
          <div className="nameHeadProfList">{profile.name}</div>
          <div className="nameSubProfList">{profile.role}</div>
        </div>
      </div>
        <svg
        onClick={()=>setDel(true)}
        width="2vw"
        viewBox="0 0 16 16"
        fill="none"
        style={{position:"fixed", right:"3.6vw"}}
      >
        <path
          d="M5.03125 2.23438H4.875C4.96094 2.23438 5.03125 2.17109 5.03125 2.09375V2.23438ZM5.03125 2.23438H10.9688V2.09375C10.9688 2.17109 11.0391 2.23438 11.125 2.23438H10.9688V3.5H12.375V2.09375C12.375 1.47324 11.8145 0.96875 11.125 0.96875H4.875C4.18555 0.96875 3.625 1.47324 3.625 2.09375V3.5H5.03125V2.23438ZM14.875 3.5H1.125C0.779297 3.5 0.5 3.75137 0.5 4.0625V4.625C0.5 4.70234 0.570312 4.76562 0.65625 4.76562H1.83594L2.31836 13.959C2.34961 14.5584 2.90039 15.0312 3.56641 15.0312H12.4336C13.1016 15.0312 13.6504 14.5602 13.6816 13.959L14.1641 4.76562H15.3438C15.4297 4.76562 15.5 4.70234 15.5 4.625V4.0625C15.5 3.75137 15.2207 3.5 14.875 3.5ZM12.2832 13.7656H3.7168L3.24414 4.76562H12.7559L12.2832 13.7656Z"
          fill="red"
        />
      </svg>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {del && (
          <div onClick={() => setDel(false)} className="blackBackDelProfile">
            <div className="delProfileBox" onClick={(e) => e.stopPropagation()}>
              <h className="delProfileBoxHead">Delete {PlayerOrCoach ? "Player" : "Coach"}</h>
              <div className="subTextDelProfile">
                Are you sure you want to delete this {PlayerOrCoach ? "Player" : "Coach"}? This action can not
                be undone.
              </div>
              <motion.div
                whileTap={{ scale: 0.9 }}
                onClick={delProfile}
                className="DPdelProfile"
              >
                Delete 
              </motion.div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProfDiv;
