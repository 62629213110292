import { motion, AnimatePresence } from "framer-motion";
import { useState} from "react";
import "./Group.css";
import ProfListC from "./profListC";

const AddPlayer = ({ CS, head }) => {




  const [editText, setEditText] = useState(true);
  const [editText2, setEditText2] = useState(true);
  const [oAdd, setoAdd] = useState(false);


  function editclick(id) {
    if(id==1){
      setEditText(!editText);
    }
    else {
      setEditText2(!editText2);
    }
    

  }


  const dropIn = {
    hidden: {
      x: "100vw",
      opacity: 1,
    },
    visible: {
      x: "0vw",
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 30,
        stiffness: 300,
      },
    },
    exit: {
      x: "100vw",
      opacity: 1,
    },
  };

  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="createFull"
    >
      <div onClick={() => CS(false)} className="back-container">
        <svg
          width="2vw"
          height="5vw"
          className="triBack"
          viewBox="0 0 99 109"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z" fill="black" />
        </svg>

        <div className="backTxt">Back</div>
      </div>

      <div className="create-Cap">
        <div className="groupHead">
          Add Person
        </div>
        <div className="GroupSubFlex">
          <div className="GroupSub">Players</div>
          <div className="edit22" style={{color:"green"}}>Add</div>
        </div>
        <ProfListC
          pic="coach"
          order={[2, 3, 1, 3]}
          scores={["3:28", "8:19", "5:15"]}
          name="Christian Shimko"
          tf={false}
        />
        <div className="GroupSubFlex2">
          <div className="GroupSub">Coaches</div>
          <div className="edit22" style={{color:"green"}}>Add</div>
        </div>
        <ProfListC
          pic="coach"
          order={[2, 3, 1, 2]}
          scores={["3:28", "8:19", "5:15"]}
          name="Christian Shimko"
          tf={false}
        />
      </div>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {oAdd && (
          <AddPlayer CS={setoAdd} />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default AddPlayer;
