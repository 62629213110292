import { useEffect, useState } from "react";
import face from "../../Profiles/unknownProfile.jpg";
import "./QuizResultDesktop.css";

const QuizResult = ({seeByClicked, currPastQuiz, currPastQuizIndex}) => {

//reference for quiz results
  const [isCorrect, setIsCorrect] = useState(Array(currPastQuiz.correctAnswers.length).fill(0));
  const [pastTitle, setPastTitle] = useState(currPastQuiz.name)
  const [pastSubtitle, setPastSubtitle] = useState(currPastQuiz.subtitle)

  useEffect(()=>{
    setIsCorrect(Array(currPastQuiz.correctAnswers.length).fill(0));
    setPastTitle(currPastQuiz.name);
    setPastSubtitle(currPastQuiz.subtitle);
  }, [currPastQuiz])

  

  return (
    <div className="innerSPA2">
      <div className="resultHeadD">
        <h className="resultHeadHead">Quiz Results</h>
        <div className="resultQuizHead">{pastTitle}</div>
        <div className="resultQuizSub">{pastSubtitle}</div>
        <div className="resultCircleD">93%</div>
      </div>
      <div className="outerQuestionResult">
        <div className="innerQuestionResult">
          <div className="viewByQuestion">View Results by Question</div>
          {isCorrect.map((question, index) => (
            <div className="questionWrapperD">
              <div className="questionBoxCircle2D">
                <div className="questionCircle2D">{index + 1}</div>
                <div className="questionBox2D">
                  <div className="QBflexD">
                    <div className="avgTXTD">Avg Score: 87%</div>
                    <div className="srBoxD" onClick={() => seeByClicked(index, currPastQuizIndex)}>
                      See Results
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="innerQuestionResult" style={{ paddingLeft: "1vw" }}>
          <div className="viewByQuestion">Player Stats</div>
          <div className="teammatesWrapperD">
            <div className="teammateFlexD">
              <div className="teammateCircleD">
                <img src={face} className="teammatePicD"></img>
              </div>
              <div className="teammateNameD">Griffin Speaks</div>
              <div className="teammatePCTD">98%</div>
            </div>
            <div className="teammateFlexD">
              <div className="teammateCircleD">
                <img src={face} className="teammatePicD"></img>
              </div>
              <div className="teammateNameD">Ben Hamilton</div>
              <div className="teammatePCTD">83%</div>
            </div>
            <div className="teammateFlexD">
              <div className="teammateCircleD">
                <img src={face} className="teammatePicD"></img>
              </div>
              <div className="teammateNameD">Christian Shimko</div>
              <div className="teammatePCTD">64%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizResult;
