import { motion, AnimatePresence } from "framer-motion";
import { useState, useRef } from "react";
import "./NewQDesktop.css";
import TimeSelect from "./TimeSelect";
import SearchMod from "../../DesktopCoachesPlaybook/Modals/SearchMod.js";
import UploadSwitch from "./UploadSwitch.js";



const NewQ = ({
  correctRef,
  wrongRef1,
  wrongRef2,
  wrongRef3,
  questionRef,
  addQuestion,
  setSelectedTime,
  PVV,
  titles,
  groupPlays,
  playsData,
  pickPic
}) => {
 

  const [openSearch, setOpenSearch] = useState(false);


  const [openUpload, setOpenUpload] = useState(false);

  const [openTimeSelector, setOpenTime] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileClick = (e) => {
    fileInputRef.current.click();
  };

  const playbookPicUpload = (e) => {
    setOpenSearch(true);
    setOpenUpload(false);
  };



  

  const handleFileChange = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
          pickPic(reader.result);
    };
    
    setOpenUpload(false);
  };

  return (
    <div className="create-Cap-Desktop">
      <div className="createHeadDesktop">New Question</div>
      <div className="createSubDesktop">Question 3</div>

      <input
        ref={questionRef}
        className="inputCreateQuestion"
        placeholder="Type your question"
      ></input>

      <input
        ref={correctRef}
        className="inputCreateQuestion"
        placeholder="Correct Answer:"
      ></input>
      <input
        ref={wrongRef1}
        className="inputCreateQuestion"
        placeholder="Wrong Choice 1:"
      ></input>
      <input
        ref={wrongRef2}
        className="inputCreateQuestion"
        placeholder="Wrong Choice 2:"
      ></input>
      <input
        ref={wrongRef3}
        className="inputCreateQuestion"
        placeholder="Wrong Choice 3:"
      ></input>
      <div className="uploadImageCreateQuestion" onClick={() => setOpenTime(true)}>
        <div>Time Per Question</div>
        <svg width=".7vw" height=".7vw" viewBox="0 0 8 9" fill="none">
          <path
            d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="uploadImageCreateQuestion" onClick={() => setOpenUpload(true)}>
        <input
          type="file"
          style={{display:"none"}}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <div>Upload Image</div>
        <svg width=".7vw" height=".7vw" viewBox="0 0 8 9" fill="none">
          <path
            d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
            fill="black"
          />
        </svg>
      </div>

      <motion.div
        className="postButtonCreateQuiz"
        whileTap={{ scale: 0.9 }}
        onClick={addQuestion}
      >
        Add Question
      </motion.div>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openTimeSelector && (
          <div className="blackBack">
            <TimeSelect CS={setOpenTime} setSelectedTime={setSelectedTime} />
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openUpload && (
          <UploadSwitch handleFileClick={handleFileClick} playbookPicUpload={playbookPicUpload}/>
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openSearch && (
          <SearchMod
          close={() => setOpenSearch(false)}
          titles={titles}
          groupPlays={groupPlays}
          PVV={PVV}
          playsData={playsData}
          pickPic={pickPic}
          ifPic={true}
        />
        )}
      </AnimatePresence>

      
    </div>
  );
};

export default NewQ;
