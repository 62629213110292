import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import "/node_modules/swiper/swiper.min.css";

import "./SPACD.css";

import NavBar from "./NavBar/NavBar.js";

import quizzes from "./JSON/Quiz.json";
import Profiles from "./JSON/profilesC.json";
import plays from "./JSON/Plays.json";
import folders from "./JSON/Folders.json";

import HomeMain from "./DesktopCoachHome/HomeMain.js";
import Profile from "./DesktopCoachHome/Modals/Profile.js";

import PlaybookDesktop from "./DesktopCoachesPlaybook/PlaybookDesktop.js";
import FolderLevel from "./DesktopCoachesPlaybook/FileSystem/folderLevel.js";
import PlayViewD from "./DesktopCoachesPlaybook/Modals/PlayViewD.js";
import NewFolder from "./DesktopCoachesPlaybook/Modals/NewFolder.js";
import DeleteFolderD from "./DesktopCoachesPlaybook/Modals/DeleteFolder.js";
import SearchMod from "./DesktopCoachesPlaybook/Modals/SearchMod.js";
import Filter from "./DesktopCoachesPlaybook/Modals/pFilter";
import UploadFiles from "./DesktopCoachesPlaybook/Modals/UploadFiles.js";

import QuizSwitch from "./DesktopCoachQuiz/QuizSwitch.js";
import SeeByQuestion from "./DesktopCoachQuiz/Modals/SeeByQuestion.js";
import CurrQuizView from "./DesktopCoachQuiz/Modals/CurrentQuizView.js";





// Coaches main single page application
const SPAC = ({ name }) => {


  ///////////////////////////////////Modals
  const [openPV, setOpenPV] = useState(false);
  const [openDF, setOpenDF] = useState(false);
  const [openNF, setOpenNF] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openProf, setOpenProf] = useState(false);
  const [openUP, setUP] = useState(false);
  const [openSearch, setSearch] = useState(false);

  ///////////////////////////////////NavBar
  const [whichPage, setPage] = useState(1);

  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current) {
      new Swiper(swiperRef.current, {
        initialSlide: 1,
        direction: "horizontal",
        loop: false,
        slidesPerView: 1,
        spaceBetween: "30vw",
        allowTouchMove: false,
      });
    }
  }, []);
  function goToSlide(index) {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index, 300); // 300 is the speed in milliseconds
    }
  }

  //////////////////////////////////Home
  //Profile info
  const ProfileInfo = Profiles[name];
  const firstName = ProfileInfo.name.split(" ")[0];
  const num = ProfileInfo.num;
  const groups = ProfileInfo.groups;

        //Quiz class
        class profile {
          constructor(
            name,
            role,
            email,
            number,
            position,
            title
          ) {
            this.name = name;
            this.role = role;
            this.email = email;
            this.number = number;
            this.position = position;
            this.title = title;
          }
        }
    
        //players state
        const [players, setPlayers] = useState(()=> {
          const newPlayers = [];
        for (let i = 0; i < 3; i++) {
          newPlayers.push(new profile(`Player ${i + 1}`, "Postion #", null, null, null, null));  // Create a new Player with a unique name
        }
        return(newPlayers);
        });
    
        //coaches state
        const [coaches, setCoaches] = useState(()=> {
          const newCoaches = [];
        for (let i = 0; i < 3; i++) {
          newCoaches.push(new profile(`Coach ${i + 1}`, "Role"));  // Create a new Player with a unique name
        }
        return(newCoaches);
        });












//////////////////////////////////Play

  const [keyn, setKeyn] = useState(0);
  const [favoritePlays, setFavoritePlays] = useState(ProfileInfo.Splays);
  const [isTog, setIsTog] = useState(false);

  const [upImgList, setUpImgList] = useState([]);

  const [resultList, setResultList] = useState([]);

  const [group, setGroup] = useState(groups[0]);
  const [groupTF, setGroupTF] = useState(new Array(groups.length).fill(false));
  let grouptf = groupTF;
  grouptf[0] = true;

  const [currIDs, setCurrIDs] = useState(0);

  function SCP(plays) {
    console.log("hi")
    setCurrIDs(plays);
    if (plays.length > 0) {
      setOpenPV(true);
    }
  }
  const [playsData, setPlaysData] = useState({ ...plays });
  const [groupPlays, setGroupPlays] = useState(() => {
    let gp = [];
    for (let key in playsData) {
      if (group.includes(playsData[key].group)) {
        gp.push(key);
      }
    }
    return gp;
  });

  const [newPlayId, setNewPlayId] = useState(17);
  const descRef = useRef(null);
  const titRef = useRef(null);

  const [parentFolders, setParentFolders] = useState(() => {
    let pp = [];
    groupPlays.map((value, index) => {
      pp.push(playsData[value].parentFolder);
    });
    return pp;
  });

  const [playTitles, setTitles] = useState(() => {
    let tt = [];
    groupPlays.map((value, index) => {
      tt.push(playsData[value].title);
    });
    return tt;
  });

  const filterResults = (fName) => {
    let newR = [];

    parentFolders.forEach((element, index) => {
      if (element === fName.name) {
        newR.push(playsData[groupPlays[index]]);
      }
    });
    setResultList(newR);
  };

  useEffect(() => {
    filterResults(highestLevel);
  }, [parentFolders]);

/////recursive tree
  class node {
    constructor(name, childNodes, parentNode, ii) {
      this.name = name;
      this.childNodes = childNodes;
      this.parentNode = parentNode;
      this.ii = ii;
    }

    setCN(CN) {
      this.childNodes.push(CN);
    }
  }
  let names = [];
  let parents = [];
  for (const [name, par] of Object.entries(folders)) {
    names.push(name);
    parents.push(par);
  }
  function recurseTree(name, parent, ii) {
    if (parents.includes(name)) {
      let childNodes = [];
      let i = 0;
      while (parents.includes(name)) {
        const index = parents.indexOf(name);
        childNodes.push(recurseTree(names[index], name, i));
        names.splice(index, 1);
        parents.splice(index, 1);
        i++;
      }
      return new node(name, childNodes, parent, ii);
    } else {
      return new node(name, [], parent, 0);
    }
  }
  const [tree, setTree] = useState(recurseTree("Running Backs"));

  ////////////////////////////////////////////recursive tree

  const [highestLevel, setHighestLevel] = useState(tree);
  const [HLC, setHLC] = useState(false);
  const [highestLevel2, setHighestLevel2] = useState(tree);
  const [zzz, setZZZ] = useState([false, false, false]);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const [nodeList, setNodeList] = useState([tree]);
  const [bof, setBof] = useState(true);

  let NLkey = 0;

  const [folderComponent, setFolderComponent] = useState(
    <FolderLevel
      folderClicked={folderClicked}
      names={highestLevel.childNodes}
    ></FolderLevel>
  );

  function addFolder(namez) {
    const newf = new node(namez, [], highestLevel, 0);

    highestLevel.setCN(newf);
    setHLC(!HLC);
  }

  function DeleteFolder() {
    let newArray = highestLevel2.childNodes.filter(
      (item) => item !== highestLevel
    );
    highestLevel2.childNodes = newArray;
    setHLC(!HLC);
  }

  function folderClicked(level, name, zzz) {
    setBof(true);
    if (level > 1) {
      let nn = nodeList.slice();

      nn.push(name);
      setNodeList(nn);

      setZZZ(zzz);
      setHighestLevel2(highestLevel);
      setHighestLevel(name);
    } else {
      let nn = nodeList.slice();
      if (NLkey > 1) {
        nn.splice(-1, 2);
      }

      nn.push(name);
      setNodeList(nn);

      setZZZ(zzz);
      setHighestLevel(name);
    }
  }

  function back() {
    if (nodeList.length > 2) {
      setBof(false);
      let nn = nodeList.slice();
      nn.pop();
      setNodeList(nn);

      setHighestLevel(highestLevel2);
      setHighestLevel2(nn[nn.length - 2]);
    } else {
      let nn = nodeList.slice();
      nn.pop();
      setNodeList(nn);
      setHighestLevel(highestLevel2);
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      if (highestLevel.name != "Running Backs") {
        setFolderComponent(() => {
          let newFC = [];
          let newZZZ = new Array(highestLevel2.childNodes.length).fill(false);
          newZZZ[highestLevel.ii] = true;
          newFC.push(
            <FolderLevel
              folderClicked={folderClicked}
              names={highestLevel2.childNodes}
              keyn={keyn}
              zzz={bof ? zzz : newZZZ}
              level={1}
              currClicked={highestLevel.name}
              back={back}
            ></FolderLevel>
          );
          newFC.push(
            <FolderLevel
              folderClicked={folderClicked}
              names={highestLevel.childNodes}
              keyn={keyn}
              level={2}
              currClicked={highestLevel.name}
              back={back}
            ></FolderLevel>
          );
          return newFC;
        });
      } else {
        setFolderComponent(() => {
          let newFC = [];
          let newZZZ = new Array(highestLevel2.childNodes.length).fill(false);
          newFC.push(
            <FolderLevel
              folderClicked={folderClicked}
              names={highestLevel2.childNodes}
              keyn={keyn}
              zzz={newZZZ}
              level={1}
              currClicked={highestLevel.name}
              back={back}
            ></FolderLevel>
          );
          return newFC;
        });
      }
    }
    setIsFirstRender(true);
    filterResults(highestLevel);
    setKeyn((prevKey) => prevKey + 1);
  }, [highestLevel, HLC]);

  useEffect(() => {
    NLkey = nodeList.length;
  }, [nodeList]);

  const handleDeletePlay = (id) => {
    setPlaysData((prev) => {
      const { [id]: _, ...newPlaysData } = prev;
      return newPlaysData;
    });

    setGroupPlays((prev) => prev.filter((playId) => playId !== id));
    if (favoritePlays.includes(id)) {
      setFavoritePlays((prev) => prev.filter((playId) => playId !== id));
    }
  };

  useEffect(() => {
  }, [favoritePlays]);

  const handleAddPlay = () => {
    let newPlay = {
      title: titRef.current.value,
      coach: "Coach Smith",
      group: "Running Backs",
      id: newPlayId,
      pic: upImgList,
      caption: descRef.current.value,
      parentFolder: highestLevel.name,
    };

    setPlaysData((prev) => ({
      ...prev,
      [newPlayId]: newPlay,
    }));

    if (isTog === true) {
      setFavoritePlays((prev) => [...prev, newPlayId]);
    }
    setGroupPlays((prev) => [...prev, newPlayId]);

    setNewPlayId(newPlayId + 1);
    setUpImgList([]);

    descRef.current.value = "";
    titRef.current.value = "";
    setIsTog(false);
  };

  useEffect(() => {
    let parentFolders = [];
    groupPlays.map((value, index) => {
      parentFolders.push(playsData[value].parentFolder);
    });
    let t = [];
    groupPlays.map((value, index) => {
      t.push(playsData[value].title);
    });
    setParentFolders(parentFolders);
    setTitles(t);

    filterResults(highestLevel.name);
  }, [groupPlays]);






  
  //////////////////////////////////Quizzes
  //Quiz Class
  class QuizObj {
    constructor(
      name,
      subtitle,
      dueDate,
      group,
      coverPlay,
      coachName,
      pics,
      dateOfCreation,
      questions,
      possibleAnswers,
      id,
      correctAnswers,
      timeList
    ) {
      this.name = name;
      this.subtitle = subtitle;
      this.dueDate = dueDate;
      this.group = group;
      this.coverPlay = coverPlay;
      this.coachName = coachName;
      this.pics = pics;
      this.dateOfCreation = dateOfCreation;
      this.questions = questions;
      this.possibleAnswers = possibleAnswers;
      this.id = id;
      this.correctAnswers = correctAnswers;
      this.timeList = timeList;
    }
  }

  //Pulling the quiz info, creating quiz objects for all, then separating into past/current
  const [currentQuizzes, setCurrentQuizzes] = useState(() => {
    let n = [];
    let date = new Date("03/22/2024");

    for (const [id, quizData] of Object.entries(quizzes)) {
      let dueDate = new Date(quizData.Due_date);
      if (quizData.Group === "Running Backs") {
        if (dueDate > date) {
          const quiz = new QuizObj(
            quizData.Title,
            quizData.Subtitle,
            quizData.Due_date,
            quizData.Group,
            quizData.coverPlay,
            quizData.Coach_name,
            quizData.pics,
            quizData.date_of_creation,
            quizData.questions,
            quizData.possible_answers,
            parseInt(id),
            quizData.correct_answers,
            quizData.timeList
          );
          n.push(quiz);
        }
      }
    }
    return n;
  });

  const [pastQuizzes, setPastQuizzes] = useState(() => {
    let n = [];
    let date = new Date("03/22/2024");

    for (const [id, quizData] of Object.entries(quizzes)) {
      let dueDate = new Date(quizData.Due_date);
      if (quizData.Group === "Running Backs") {
        if (dueDate < date) {
          const quiz = new QuizObj(
            quizData.Title,
            quizData.Subtitle,
            quizData.Due_date,
            quizData.Group,
            quizData.coverPlay,
            quizData.Coach_name,
            quizData.pics,
            quizData.date_of_creation,
            quizData.questions,
            quizData.possible_answers,
            parseInt(id),
            quizData.correct_answers,
            quizData.timeList
          );
          n.push(quiz);
        }
      }
    }
    return n;
  });

  //Add new quiz object handler
  function newQuiz(
    name,
    subtitle,
    dueDate,
    group,
    coverPlay,
    coachName,
    pics,
    dateOfCreation,
    questions,
    possibleAnswers,
    id,
    correctAnswers,
    timeList
  ) {
    setCurrentQuizzes((prev) => [
      ...prev,
      new QuizObj(
        name,
        subtitle,
        dueDate,
        group,
        coverPlay,
        coachName,
        pics,
        dateOfCreation,
        questions,
        possibleAnswers,
        id,
        correctAnswers,
        timeList
      ),
    ]);
  }

  //set and open the currQuiz modal
  const [oCurrQuiz, setOcurrQuiz] = useState(false);
  const [currQuiz, setCurrQuiz] = useState(currentQuizzes[0]);

  function currQuizClicked(n) {
    setCurrQuiz(n);
    setOcurrQuiz(true);
  }

  //open and Set the state of seeByQuestion info
  const [seeBy, setSeeBy] = useState(false);

  let pq = null;
  if (pastQuizzes[0].pics[0].substring(0, 2) == "./") {
    pq = require(`${pastQuizzes[0].pics[0]}`);
  } else {
    pq = pastQuizzes[0].pics[0];
  }

  const [title, setTitle] = useState(pastQuizzes[0].questions[0]);
  const [correct, setCorrect] = useState(pastQuizzes[0].correctAnswers[0]);
  const [ans, setAns] = useState(pastQuizzes[0].possibleAnswers[0]);
  const [QP, setQP] = useState(pq);

  function seeByClicked(n, whichQuiz) {
    setTitle(pastQuizzes[whichQuiz].questions[n]);
    setCorrect(pastQuizzes[whichQuiz].correctAnswers[n]);
    setAns(pastQuizzes[whichQuiz].possibleAnswers[n]);
    let pq = null;
    if (pastQuizzes[whichQuiz].pics[n].substring(0, 2) == "./") {
      pq = require(`${pastQuizzes[0].pics[n]}`);
    } else {
      pq = pastQuizzes[whichQuiz].pics[n];
    }
    setQP(pq);

    setSeeBy(true);
  }





  return (
    <div className="appSPA2">
      {whichPage === 0 && (
        <QuizSwitch
          currentQuizzes={currentQuizzes}
          currQuizClicked={currQuizClicked}
          seeByClicked={seeByClicked}
          pastQuizzes={pastQuizzes}
          newQuiz={newQuiz}
          playsData={playsData}
          playTitles={playTitles}
          groupPlays={groupPlays}
          SCP={SCP}
          QuizObj={QuizObj}
        />
      )}

      {whichPage === 1 && (
        <HomeMain
          groupPlays={groupPlays}
          currentQuizzes={currentQuizzes}
          name={firstName}
          setOpenProf={setOpenProf}
          currQuizClicked={currQuizClicked}
          playsData={playsData}
          SCP={SCP}
          favoritePlays={favoritePlays}
          players={players} profile={profile} coaches={coaches} setCoaches={setCoaches} setPlayers={setPlayers}
        />
      )}

      {whichPage === 2 && (
        <PlaybookDesktop
        setOpenFilter={setOpenFilter}
        setOpenNF={setOpenNF}
setSearch={setSearch}
back={back}
keyn={keyn}
folderComponent={folderComponent}
resultList={resultList}
SCP={SCP}
setOpenDF={setOpenDF}
titRef={titRef}
descRef={descRef}
setUP={setUP}
upImgList={upImgList}
setIsTog={setIsTog}
isTog={isTog}
handleAddPlay={handleAddPlay}
highestLevel={highestLevel}
        />
      )}

      <NavBar
        i={whichPage}
        setPage={setPage}
        goToSlide={goToSlide}
      />

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openProf && (
          <Profile
            close={setOpenProf}
            num={num}
            name={ProfileInfo.name}
            group={groups[0]}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openFilter && (
          <Filter
            handleClose={() => setOpenFilter(false)}
            groupTF={groupTF}
            setGroup={setGroup}
            setGroupTF={setGroupTF}
            group={group}
            groupList={["Running Backs"]}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openPV && (
          <PlayViewD
            close={() => setOpenPV(false)}
            plays={currIDs}
            setFavoritePlays={setFavoritePlays}
            favoritePlays={favoritePlays}
            handleDeletePlay={handleDeletePlay}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openNF && (
          <NewFolder
            close={() => setOpenNF(false)}
            fName={highestLevel.name}
            addFolder={addFolder}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openDF && (
          <DeleteFolderD close={() => setOpenDF(false)} DF={DeleteFolder} />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openSearch && (
          <SearchMod
            close={() => setSearch(false)}
            titles={playTitles}
            starred={groupPlays}
            PVV={SCP}
            playsData={playsData}
            ifPic={false}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openUP && (
          <UploadFiles
            close={() => setUP(false)}
            upImgList={upImgList}
            setUpImgList={setUpImgList}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {oCurrQuiz && (
          <CurrQuizView
            currentQuizzes={currentQuizzes}
            setCurrentQuizzes={setCurrentQuizzes}
            close={() => setOcurrQuiz(false)}
            openCurrQuiz={currQuizClicked}
            quiz={currQuiz}
          />
        )}
      </AnimatePresence>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {seeBy && (
          <SeeByQuestion
            currentQuizzes={currentQuizzes}
            titlen={title}
            correctn={correct}
            ansn={ans}
            pqn={QP}
            setCurrentQuizzes={setCurrentQuizzes}
            close={() => setSeeBy(false)}
            openCurrQuiz={currQuizClicked}
            quiz={currQuiz}
          />
        )}
      </AnimatePresence>

      

    </div>
  );
};

export default SPAC;
