
import "./PlaybookDesktop.css"

import NewFile from "./NewFile/NewFile";
import FileSystem from "./FileSystem/FileSystem";

const PlaybookDesktop = ({setOpenNF,
    setSearch,
    back,
    keyn,
    folderComponent,
    resultList,
    SCP,
    setOpenDF,
    titRef,
    descRef,
    setUP,
    upImgList,
    setIsTog,
    isTog,
    handleAddPlay,
    setOpenFilter,
    highestLevel
}) => {
      
   

    return(
        
        <div className="overallSPAP">
          
          {/* Playbook Head */}
          <h className="playbookHead">Playbook</h>
          {/* SubHead flex */}
          <div className="playbookSubFlex" onClick={() => setOpenFilter(true)}>
            <div className="playbookSub">Running Backs</div>
            <svg
              className="triD"
              width="1vw"
              height="1.5vw"
              viewBox="0 0 181 99"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M90.5 99L0.866364 0.75L180.134 0.75L90.5 99Z"
                fill="#93A3BB"
              />
            </svg>
          </div>

          {/* Flex with playbook and new file */}
          <div className="innerSPAP">
            <FileSystem
            setOpenNF={setOpenNF}
            setSearch={setSearch}
            back={back}
            keyn={keyn}
            folderComponent={folderComponent}
            resultList={resultList}
            SCP={SCP}
            setOpenDF={setOpenDF}
            />

            <NewFile
            titRef={titRef}
            descRef={descRef}
            setUP={setUP}
            upImgList={upImgList}
            setIsTog={setIsTog}
            isTog={isTog}
            handleAddPlay={handleAddPlay}
            highestLevel={highestLevel}
            />
          </div>
        </div>
    )
}

export default PlaybookDesktop;



