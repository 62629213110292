import { useState, useRef, useEffect } from "react";
import "./CreateQuiz.css";
import CreateQuizLeftCol from "./CreateQuizLeftCol.js";
import NewQ from "./NewQDesktop.js";
import QuestionList from "./QuestionList.js";
import NewQuestionList from "./QuestionList.js";

const CreateQuiz = ({
  setOpenCal,
  newQuiz,
  setCreate,
  playTitles,
  groupPlays,
  SCP,
  playsData,
  currentQuizzes,
  dueDate,
  setDueDate,
}) => {
  //New quiz input references
  const titleQuiz = useRef(null);
  const subQuiz = useRef(null);

  //Question class
  class question {
    constructor(answers, correct, q, time, pic) {
      this.correct = correct;
      this.answers = answers;
      this.q = q;
      this.time = time;
      this.pic = pic;
    }
  }

  //toggles edit/remove for new quiz question list
  const [ER, setER] = useState(false);

  //Question list state
  const [newQuestionList, setNewQuestionList] = useState([]);

  //Questions to remove
  const [removeQuestions, setRemoveQuestions] = useState([]);

  //Delete questions handler
  function deleteQuestions() {
    setNewQuestionList(
      newQuestionList.filter((item, index) => !removeQuestions.includes(index))
    );
    setRemoveQuestions([]);
  }

  //New Question input references
  const correctRef = useRef(null);
  const wrongRef1 = useRef(null);
  const wrongRef2 = useRef(null);
  const wrongRef3 = useRef(null);
  const questionRef = useRef(null);
  const [selectedTime, setSelectedTime] = useState("15s");
  const [Qpic, setQpic] = useState(null);

  //Handles add question
  function addQuestion() {
    let answerRefs = [
      correctRef.current.value,
      wrongRef1.current.value,
      wrongRef2.current.value,
      wrongRef3.current.value,
    ];

    let newq = new question(
      answerRefs,
      correctRef.current.value,
      questionRef.current.value,
      selectedTime,
      Qpic
    );

    setNewQuestionList((prev) => [...prev, newq]);
  }

  useEffect(()=> {
    correctRef.current.value = "";
    wrongRef1.current.value = "";
    wrongRef2.current.value = "";
    wrongRef3.current.value = "";
    questionRef.current.value = "";
    setSelectedTime("15s");
    setQpic(null);
  }, [newQuestionList])
  /////////!! Quiz addition handler
  //Add new quiz handler
  function addNewQuiz() {
    let questionPicList = [];
    let possibleAnswers2D = [];
    let QuestionTitleList = [];
    let correctAnswerList = [];
    let QuestionTimes = [];

    let titleQuizTemp = titleQuiz.current.value;
    let subQuizTemp = subQuiz.current.value;
    let dueDateTemp = dueDate;
    let newQuestionListTemp = [...newQuestionList];

    newQuestionListTemp.map((item, index) => {
      questionPicList.push(item.pic);
      QuestionTitleList.push(item.q);
      possibleAnswers2D.push(item.answers);
      correctAnswerList.push(item.correct);
      QuestionTimes.push(item.time);
    });

    newQuiz(
      titleQuizTemp,
      subQuizTemp,
      dueDateTemp,
      "Running Backs",
      newQuestionListTemp.pic,
      "Coach Smith",
      questionPicList,
      "08/06/24",
      QuestionTitleList,
      possibleAnswers2D,
      currentQuizzes.length,
      correctAnswerList,
      QuestionTimes
    );

    titleQuiz.current.value = "";
    subQuiz.current.value = "";
    setDueDate(null);
    setNewQuestionList([]);
    setSelectedTime("15s");
    correctRef.current.value = "";
    wrongRef1.current.value = "";
    wrongRef2.current.value = "";
    wrongRef3.current.value = "";
    questionRef.current.value = "";
    setQpic(null);
  }

  return (
    <div className="CreateSPA">
      {/* Back Container */}
      <div onClick={() => setCreate(false)} className="back-container-create">
        <svg
          width=".9vw"
          height="1.2vw"
          className="triBack"
          viewBox="0 0 99 109"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z" fill="black" />
        </svg>
        Back
      </div>

      <div className="innercreate1">
        <CreateQuizLeftCol
          setOpenCal={setOpenCal}
          newQuiz={addNewQuiz}
          setDueDate={setDueDate}
          titleQuiz={titleQuiz}
          subQuiz={subQuiz}
          deleteQuestions={deleteQuestions}
          setRemoveQuestions={setRemoveQuestions}
        />
      </div>

      <QuestionList
        deleteQuestions={deleteQuestions}
        setRemoveQuestions={setRemoveQuestions}
        questionL={newQuestionList}
        ER={ER}
        setER={setER}
      />

      <div className="innercreate2">
        <NewQ
          addQuestion={addQuestion}
          correctRef={correctRef}
          wrongRef1={wrongRef1}
          wrongRef2={wrongRef2}
          wrongRef3={wrongRef3}
          questionRef={questionRef}
          setSelectedTime={setSelectedTime}
          titles={playTitles}
          groupPlays={groupPlays}
          PVV={SCP}
          playsData={playsData}
          pickPic={setQpic}
        />
      </div>
    </div>
  );
};

export default CreateQuiz;
