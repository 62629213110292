import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Roster.css";
import "/node_modules/swiper/swiper.min.css";
import ProfList from "./ProfList.js";
import GroupList from "./GroupList";
import Group from "../../../Modals/Group/Group";
import NewProfile from "../../../Modals/NewProfile.js";

// Roster component
const Roster = ({players, coaches, setCoaches, setPlayers, profile}) => {



  // Opens new profile modal
  const [OpenNewProfile, setOpenNewProfile] = useState(false);

  // To determine if user is on player or coach
  const [PlayerOrCoach, setPlayerOrCoach] = useState(true);

    // Opens new group modal
  const [openGroup, setOpenGroup] = useState(false);


  const [whichSlide, setSlide] = useState(0);
  const [whichG, setWhichG] = useState("");

  // To switch slides of roster
  const switchUnder = (e, set) => {
    setPlayerOrCoach(set);
    const headList = [
      document.getElementsByClassName("navItem1Roster"),
      document.getElementsByClassName("navItem2Roster"),
      document.getElementsByClassName("navItem3Roster"),
    ];
    headList.map((e, index) => {
      headList[index][0].style.borderBottom = "none";
    });
    e.target.style.borderBottom = ".2vw solid black";
    setSlide(e.target.id);
  };

  return (
    <motion.div
      className="fullRoster"
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div className="Roster-Cap">

{/* Roster nav head component */}
        <div className="navHeadRoster">
          <div
            className="navItem1Roster"
            id="0"
            onClick={(e) => switchUnder(e, true)}
            style={{ borderBottom: ".2vw solid black" }}
          >
            Players
          </div>

          <div
            className="navItem2Roster"
            onClick={(e) => switchUnder(e, false)}
            id="1"
          >
            Coaches
          </div>

          <div
            className="navItem3Roster"
            onClick={(e) => switchUnder(e, false)}
            id="2"
          >
            Groups
          </div>
        </div>


{/* Add new profile button components */}
        {whichSlide == 0 && (
          <div
            className="newProfileButton"
            onClick={() => {
              setOpenNewProfile(true);
            }}
          >
            <div>
              <div className="newProfileMainText">New Player</div>
              <div className="tapToAdd">Tap to Add</div>
            </div>

            <svg width="1.5vw" height="1.5vw" viewBox="0 0 8 9" fill="none">
              <path
                d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
                fill="black"
              />
            </svg>
          </div>
        )}
        {whichSlide == 1 && (
          <div
            className="newProfileButton"
            onClick={() => setOpenNewProfile(true)}
          >
            <div>
              <div className="newProfileMainText">New Coach</div>
              <div className="tapToAdd">Tap to Add</div>
            </div>

            <svg width="1.5vw" height="1.5vw" viewBox="0 0 8 9" fill="none">
              <path
                d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
                fill="black"
              />
            </svg>
          </div>
        )}


{/* Switches between players, coaches, groups */}
        {whichSlide == 0 && <ProfList profiles={players} PlayerOrCoach={PlayerOrCoach} setPlayers={setPlayers} setCoaches={setCoaches} />}
        {whichSlide == 1 && <ProfList profiles={coaches} PlayerOrCoach={PlayerOrCoach} setCoaches={setCoaches} setPlayers={setPlayers}  />}
        {whichSlide == 2 && (
          <GroupList setOpenGroup={setOpenGroup} setWhichG={setWhichG} />
        )}

        <AnimatePresence
          initial={false}
          wait={true}
          onExitComplete={() => null}
        >
          {openGroup && <Group CS={() => setOpenGroup(false)} head={whichG} />}
        </AnimatePresence>

        <AnimatePresence
          initial={false}
          wait={true}
          onExitComplete={() => null}
        >
          {OpenNewProfile && (
            <NewProfile
              close={() => setOpenNewProfile(false)}
              PlayerOrCoach={PlayerOrCoach}
              setCoaches={setCoaches}
              setPlayers={setPlayers}
              profile={profile}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default Roster;
