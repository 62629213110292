import "./UploadSwitch.css"

const UploadSwitch = ({ playbookPicUpload, handleFileClick }) => {
  return (
    <div>
            <div className="blackBack" >
              <div className="upModal">
                <div className="pbIcon" onClick={playbookPicUpload}>
                  <svg
                    fill={"#0072e2"}
                    width="4.2vw"
                    height=""
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 300 300"
                  >
                    <path d="m160.99,223.13c1.69,1.69,2.59,3.9,2.47,6.08-.11,1.99-1.03,3.79-2.59,5.09-1.36,1.29-3.32,2.03-5.4,2.03s-4.15-.78-5.52-2.15l-10.85-10.85-11.05,11.06-.25.16c-1.35.9-2.86,1.78-5.07,1.78s-4.15-.78-5.52-2.15c-1.69-1.69-2.58-3.9-2.46-6.08.11-1.98,1.02-3.77,2.56-5.07l10.75-10.74-10.85-10.85c-1.69-1.68-2.58-3.9-2.46-6.08.11-1.98,1.02-3.78,2.58-5.08,1.67-1.61,3.83-2.47,5.96-2.35,1.98.11,3.77,1.02,5.07,2.57l10.74,10.74,10.85-10.84c1.68-1.69,3.9-2.59,6.08-2.47,1.98.11,3.78,1.03,5.08,2.58,1.61,1.67,2.46,3.84,2.35,5.97-.11,1.97-1.02,3.77-2.57,5.06l-10.74,10.75,10.84,10.84Z" />
                    <path d="m91.69,171.69c1.69,1.68,2.58,3.9,2.46,6.08-.11,1.98-1.02,3.78-2.58,5.08-1.37,1.3-3.32,2.04-5.4,2.04s-4.15-.79-5.52-2.16l-10.85-10.85-10.85,10.85c-1.37,1.37-3.38,2.16-5.52,2.16s-4.15-.79-5.52-2.16c-1.69-1.68-2.58-3.9-2.46-6.08.11-1.97,1.01-3.77,2.56-5.07l10.74-10.74-10.84-10.84c-1.69-1.69-2.58-3.91-2.46-6.08.11-1.99,1.02-3.78,2.57-5.08,1.67-1.62,3.85-2.47,5.97-2.35,1.97.11,3.77,1.02,5.07,2.56l10.74,10.74,10.85-10.84c1.68-1.68,3.89-2.58,6.08-2.46,1.98.11,3.78,1.02,5.08,2.58,1.61,1.67,2.46,3.83,2.34,5.96-.11,1.98-1.02,3.77-2.56,5.07l-10.74,10.74,10.84,10.85Z" />
                    <path d="m221.97,93.31c1.68,1.68,2.58,3.9,2.46,6.08-.11,1.98-1.03,3.78-2.59,5.08-1.36,1.3-3.32,2.04-5.4,2.04s-4.15-.79-5.52-2.16l-10.85-10.84-10.84,10.84c-1.37,1.37-3.38,2.16-5.53,2.16s-4.15-.79-5.52-2.16c-1.68-1.68-2.58-3.9-2.46-6.08.11-1.97,1.02-3.77,2.57-5.06l10.74-10.74-10.85-10.85c-1.68-1.69-2.58-3.9-2.46-6.08.11-1.99,1.02-3.78,2.58-5.08,1.67-1.61,3.84-2.47,5.96-2.35,1.98.11,3.77,1.02,5.07,2.56l10.75,10.75,10.84-10.85c1.69-1.69,3.92-2.59,6.08-2.46,1.98.11,3.78,1.02,5.08,2.58,1.61,1.67,2.47,3.83,2.35,5.96-.11,1.98-1.02,3.77-2.57,5.07l-10.74,10.74,10.85,10.85Z" />
                    <path d="m226.05,220.98c-15.72,0-28.51,12.79-28.51,28.51s12.79,28.51,28.51,28.51,28.51-12.79,28.51-28.51-12.79-28.51-28.51-28.51Zm0,41.24c-7.02,0-12.73-5.71-12.73-12.73s5.71-12.73,12.73-12.73,12.73,5.83,12.73,12.73-5.71,12.73-12.73,12.73Z" />
                    <path d="m235,181.88v13.13c0,4.03-3.44,7.31-7.68,7.31s-8.1-3.53-8.1-7.7v-12.45c-2-30.94-27.4-36.96-56.8-43.92l-1.26-.3c-29.42-6.59-66.09-14.8-66.09-57.41v-29.47l-5.33,6.05c-1.35,1.66-3.27,2.62-5.41,2.71-1.97.09-3.94-.58-5.56-1.87-3.32-2.66-3.71-7.5-.9-11.02l.1-.12,19.44-21.97c1.35-1.89,3.43-2.85,6.19-2.85s4.85.96,6.2,2.86l19.1,22.08c2.82,3.52,2.43,8.36-.89,11.02l-.21.15c-1.7,1.13-3.54,1.78-5.07,1.78-2.23,0-4.23-.87-5.94-2.58l-.13-.14-5.38-6.11v30.26c0,30.68,23.73,35.92,53.77,42.55l.25.06c15.72,3.62,31.97,7.37,45.1,15.52,15.35,9.53,23.39,23.41,24.6,42.43Z" />
                  </svg>
                </div>
                <div className="or">Or</div>

                <div className="fileUp" onClick={handleFileClick}>
                  <svg
                    fill="#0072e2"
                    height=""
                    width="3.2vw"
                    version="1.1"
                    viewBox="0 0 471.2 471.2"
                  >
                    <g>
                      <g>
                        <path
                          d="M457.7,230.15c-7.5,0-13.5,6-13.5,13.5v122.8c0,33.4-27.2,60.5-60.5,60.5H87.5c-33.4,0-60.5-27.2-60.5-60.5v-124.8
			c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v124.8c0,48.3,39.3,87.5,87.5,87.5h296.2c48.3,0,87.5-39.3,87.5-87.5v-122.8
			C471.2,236.25,465.2,230.15,457.7,230.15z"
                        />
                        <path
                          d="M159.3,126.15l62.8-62.8v273.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V63.35l62.8,62.8c2.6,2.6,6.1,4,9.5,4
			c3.5,0,6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1l-85.8-85.8c-2.5-2.5-6-4-9.5-4c-3.6,0-7,1.4-9.5,4l-85.8,85.8
			c-5.3,5.3-5.3,13.8,0,19.1C145.5,131.35,154.1,131.35,159.3,126.15z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
  );
};

export default UploadSwitch;