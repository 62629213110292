import face from "../../../Profiles/unknownProfile.jpg";
import "./PlayNot.css";
import { motion } from "framer-motion";

// Play notification component
const PlayNot = ({ play, duration, SCP }) => {

    const playName = play.title;
    const folderName = play.parentFolder;

  return (
    <motion.div className="PlayNotification" onClick={()=>SCP([play])} whileTap={{ scale: 0.9 }}>
        <div className="PlayNotificationCoachPic">
            <img src={face} style={{width: "100%"}} />
        </div>
        <div className="PlayNotificationInfo">{playName} was added to {folderName} </div>
        <div className="PlayNotificationDuration">{duration} ago</div>

    </motion.div>
  );
};

export default PlayNot;