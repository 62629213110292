import "./QuestionList.css";
import { motion } from "framer-motion";
import NewQuestionD from "./NewListQuestionDesktop.js";

const NewQuestionList = ({ questionL, ER, setER, deleteQuestions, setRemoveQuestions }) => {
  return (
    <div className="innercreate1">
      <div className="questionListHeaderFlex">
        <div className="questionListHeader">Questions</div>
        
        <div
          className="editRemove"
          onClick={!ER ? () => {
            setER(!ER);
          } : () => {
            deleteQuestions();
            setER(!ER);
          }}
        >
          {!ER ? "Edit" : "Remove"}
        </div>
      </div>

      <div className="questionList">
        {questionL.map((item, index) => {
          return <NewQuestionD item={item} ER={ER} index={index} setRemoveQuestions={setRemoveQuestions} />;
        })}
      </div>
    </div>
  );
};

export default NewQuestionList;
