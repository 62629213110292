import "./ProfListGroup.css";
import { React, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ProfDivGroup from "./ProfDivGroup";


const ProfListGroup = (props) => {



  const orderone = props.order;



  return (
    <motion.div
      className="profListGroup"
    >
      {orderone.map((index, value) => (
        <ProfDivGroup setDel={props.setDel} index={index} tfd={props.tfd} tf={props.tf} />
      ))}

      
    </motion.div>
  );
};

export default ProfListGroup;
