import "./NewListQuestionDesktop.css";
import { motion } from "framer-motion";

import { useState, useEffect } from "react";

const NewQuestionD = ({ ER, item, index, setRemoveQuestions }) => {

  const img = (item.pic).substring(0, 2) === "./" ? require(`${item.pic}`) : item.pic;

  // Toggle expansion
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  // Onclick toggle logic
  const toggleExpand = () => {
    if (!ER) {
      setIsExpanded(!isExpanded);
    }
  };

  const toggleSelect = () => {
    if (ER) {
      setIsSelected(!isSelected);
      setRemoveQuestions(
        (oldArr) =>
          isSelected ? oldArr.filter((i) => i !== index) : [...oldArr, index]
      );
    }
  };

  useEffect(() => {
    setIsExpanded(false);
    setIsSelected(false);
    setRemoveQuestions([]);
  }, [ER]);


  return (
    <div className="overallQuestionBox">
    <div className="questionHeader" onClick={toggleExpand} style={{ cursor: "pointer" }}>
      <div className={`${!ER ? "QnumDesktop" : "QnumDesktop2"} ${isSelected ? "selected" : ""}`} onClick={ER ? toggleSelect : null}> {!ER ? index + 1 : ""}</div>
      <div className="questionPrevText">
        {item.q.length > 30 ? item.q.slice(0, 30) + "..." : item.q}
      </div>
      <div className="timeForQuestion">{item.time}</div>
    </div>

    <motion.div
      className="qItemContainer"
      initial={{ height: 0, opacity: 0, marginBottom: 0 }}
      animate={{ height: isExpanded ? "auto" : 0, opacity: isExpanded ? 1 : 0, marginBottom: isExpanded ? 10 : 0 }}
      exit={{ height: 0, opacity: 0 }}
      style={{ overflow: "hidden" }}
    >
      {item.pic ? <img className="qPicture" src={img} alt="Play Picture" /> : null}
      {item.answers.map((answer, index, pic) => (
        <p key={index} className="questionItem">{answer}</p>
      ))}
    </motion.div>





  </div>
  );

};

export default NewQuestionD;
