import "./profList.css";
import {React} from "react";
import { motion} from "framer-motion";
import prof from "../Profiles/prof.webp";
import griff from "../Profiles/griff.jpeg"
import ben from "../Profiles/ben.jpeg"


const ProfList = (props) => {

    const switchPic = (picName) => {
        switch(picName) {
        case "prof": 
            return prof
        break;
        case "griff":
            return griff
        break;
        case "ben":
            return ben
        break;
    }
}

const pics = [prof,ben,griff];
const names = ["Christian Shimko","Ben Hamilton","Griffin Speaks"];
const pos = ["Wide Receiver #13","Defensive Back #53","Safety #39"];
const scores = props.scores;



const pic = switchPic(props.pic);

const orderone = props.order


      // Simulate the external database value (replace with actual data fetching)
  const externalDatabaseValue = 3;

  // Create an array of numbers based on the external value
  const divCount = Array.from(
    { length: externalDatabaseValue },
    (_, index) => index + 1
  );

  const dropIn = {
    hidden: {
        opacity:0
    },
    visible: {
        opacity:1,
    }
}

    return (
        <motion.div 
        className="profList" 
        variants={dropIn}
        initial="hidden"
        animate = "visible"
        >
          {orderone.map((index, value) => (
            <div  className="div-item">
                <div className="profPic22">
                    <img src={pics[index-1]} className="pic22" />
                </div>
                <div className="name2">
                    <div className="nameGroup">
                    <div className="namehead2">{names[index-1]}</div>
                    <div className="namesub2">{pos[index-1]}</div>
                    </div>
                    
                    <div className="lTime"> {scores[index-1]} </div>
                </div>
            </div>
          ))}
        </motion.div>
    )

}

export default ProfList;

