import { useState} from "react";
import "./HomeC.css";
import { motion, AnimatePresence } from "framer-motion";
import plays from "../JSON/Plays.json";
import PlayNot from "./PlayNot.js";
import Leaderboard from "./Leaderboard.js";
import Roster from "./Roster.js";


const Home = ({  name, groups, setOpenS }) => {
  const [openLB, setOpenLB] = useState(false);
  const [openR, setOpenR] = useState(false);
  


  //establish list of all plays
  const [PlaysInfo, setPlaysInfo] = useState([]);
  for (const [playId, playData] of Object.entries(plays)) {
    if (groups.includes(playData.group)) {
      PlaysInfo.push(playData);
    }
  }



  return (
    <div
    className="Home">
      {/* header */}
      <div className="greeting">Welcome Back, {name}.</div>

      {/* Notes and playbook flex */}

      <div className="RLSFlexCap">
        <div className="RLSitemCap">
          <motion.div whileTap={{ scale: 0.9 }} onClick={()=>setOpenR(true)} className="RLSitem" >
            <svg
              width="34"
              height="46"
              viewBox="0 0 34 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="0.5" y1="1" x2="0.5" y2="45" stroke="black" />
              <line x1="34" y1="0.5" y2="0.5" stroke="black" />
              <line x1="33" y1="45.5" y2="45.5" stroke="black" />
              <line x1="33.5" x2="33.5" y2="46" stroke="black" />
              <line x1="9" y1="5.5" x2="26" y2="5.5" stroke="black" />
              <rect
                x="7.25"
                y="20.25"
                width="6.5"
                height="6.5"
                fill="black"
                stroke="black"
                stroke-width="0.5"
              />
              <rect
                x="7.25"
                y="30.25"
                width="6.5"
                height="6.5"
                fill="black"
                stroke="black"
                stroke-width="0.5"
              />
              <rect
                x="21.25"
                y="10.25"
                width="6.5"
                height="6.5"
                fill="black"
                stroke="black"
                stroke-width="0.5"
              />
              <rect
                x="7.25"
                y="10.25"
                width="6.5"
                height="6.5"
                fill="black"
                stroke="black"
                stroke-width="0.5"
              />
              <rect
                x="21.25"
                y="20.25"
                width="6.5"
                height="6.5"
                fill="black"
                stroke="black"
                stroke-width="0.5"
              />
              <rect
                x="21.25"
                y="30.25"
                width="6.5"
                height="6.5"
                fill="black"
                stroke="black"
                stroke-width="0.5"
              />
            </svg>
          </motion.div>
          <div className="RLStext">Roster</div>
        </div>
        <div className="RLSitemCap">
          <motion.div whileTap={{ scale: 0.9 }} onClick={()=>setOpenLB(true)} className="RLSitem">
            <svg
              width="41"
              height="40"
              viewBox="0 0 41 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.4375 2C29.4375 0.895432 30.3329 0 31.4375 0H34.6939H39.0016C40.1062 0 41.0016 0.89543 41.0016 2V38C41.0016 39.1046 40.1062 40 39.0016 40H31.4375C30.3329 40 29.4375 39.1046 29.4375 38V2Z"
                fill="black"
              />
              <path
                d="M14.7168 10.9656C14.7168 9.86101 15.6122 8.96558 16.7168 8.96558H19.9732H24.2809C25.3855 8.96558 26.2809 9.86101 26.2809 10.9656V38.0001C26.2809 39.1046 25.3855 40.0001 24.2809 40.0001H16.7168C15.6122 40.0001 14.7168 39.1046 14.7168 38.0001V10.9656Z"
                fill="black"
              />
              <path
                d="M0 20.6205C0 19.5159 0.895431 18.6205 2 18.6205H5.25642H9.56413C10.6687 18.6205 11.5641 19.5159 11.5641 20.6205V37.9998C11.5641 39.1044 10.6687 39.9998 9.56412 39.9998H2C0.895432 39.9998 0 39.1044 0 37.9998V20.6205Z"
                fill="black"
              />
            </svg>
          </motion.div>
          <div className="RLStext">Leaderboard</div>
        </div>
        <div className="RLSitemCap">
          <motion.div whileTap={{ scale: 0.9 }} onClick={()=>setOpenS(true)}  className="RLSitem">
            <svg
              width="34"
              height="46"
              viewBox="0 0 34 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="0.5" y1="1" x2="0.5" y2="45" stroke="black" />
              <line x1="34" y1="0.5" y2="0.5" stroke="black" />
              <line x1="33" y1="45.5" y2="45.5" stroke="black" />
              <line x1="33.5" x2="33.5" y2="46" stroke="black" />
              <line x1="9" y1="5.5" x2="24" y2="5.5" stroke="black" />
              <path
                d="M17 14L19.2451 20.9098H26.5106L20.6327 25.1803L22.8779 32.0902L17 27.8197L11.1221 32.0902L13.3673 25.1803L7.48944 20.9098H14.7549L17 14Z"
                fill="black"
              />
            </svg>
          </motion.div>
          <div className="RLStext">Starred</div>
        </div>
      </div>

      {/* Notifications */}
      <div className="notificationsC">
        <div className="notHeaderC">Notifications</div>
        <div className="notCapC">
          {/* Map each play to a component */}
          {PlaysInfo.map((item, index) => {
            return (
              <PlayNot play={item} duration={`${index * 2 - 1 + 4} hours`} />
            );
          })}
        </div>
      </div>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openLB && (
          <Leaderboard CS={setOpenLB}/>
        )}
      </AnimatePresence>
      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {openR && (
          <Roster close={setOpenR}/>
        )}
      </AnimatePresence>
      
    </div>
  );
};

export default Home;
