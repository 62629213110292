import { motion, AnimatePresence } from "framer-motion";
import { useState, useRef } from "react";
import "./Group.css";
import ProfListGroup from "./ProfListGroup";

const Group = ({ CS, head }) => {


  const [playersOrCoaches, setPlayersOrCoaches] = useState(true);

  const [editText, setEditText] = useState(true);
  const [editText2, setEditText2] = useState(true);
  const [oAdd, setoAdd] = useState(false);


  function editclick(id) {
    if(id==1){
      setEditText(!editText);
    }
    else {
      setEditText2(!editText2);
    }
    

  }




  return (
    
    <motion.div
      className="groupOver"
      onClick={CS}
    >

      <div className="groupInner" onClick={(e) => e.stopPropagation()}>
        <div className="groupHead">
          {head}
          <motion.svg
          whileTap={{ scale: 0.9 }}
            width="3.25vh"
            height="3.25vh"
            viewBox="0 0 45 45"
            fill="none"
            className="addClass"
            onClick={()=> setoAdd(true)}
          >
            <rect width="45" height="45" rx="15" fill="#0072E2" />
            <rect
              x="20.9453"
              y="12.1436"
              width="3.11642"
              height="20.7143"
              rx="1.55821"
              fill="white"
            />
            <rect
              width="19.4776"
              height="3.31429"
              rx="1.65714"
              transform="matrix(1 0 0 -1 12.7637 24.1589)"
              fill="white"
            />
          </motion.svg>
        </div>
        <div className="GroupFlexSub">
          <div className="PlayersOrCoachFlex">
          <div className="GroupSub" style={playersOrCoaches ? {borderBottom: ".3vh solid black"} : {borderBottom:"none"}} onClick={()=> setPlayersOrCoaches(true)}>Players</div>
          <div className="GroupSub" style={!playersOrCoaches ? {borderBottom: ".3vh solid black"} : {borderBottom:"none"}} onClick={()=> setPlayersOrCoaches(false)}>Coaches</div>

          </div>
          
          <div className="groupEdit" onClick={()=> editclick(1)}>{editText ? "Edit" : "Remove"}</div>
        </div>
        {playersOrCoaches && <ProfListGroup
          pic="coach"
          order={[2, 3, 1, 3]}
          scores={["3:28", "8:19", "5:15"]}
          name="Christian Shimko"
          tf={editText}
        />}
        {!playersOrCoaches && <ProfListGroup
          pic="coach"
          order={[2, 3, 1, 3]}
          scores={["3:28", "8:19", "5:15"]}
          name="Christian Shimko"
          tf={editText}
        />}

        <div className="AddButtonNewProfileOuter">
        <motion.div className="AddButtonNewProfile" onClick={CS}  whileTap={{ scale: 0.9 }}>
            Back
        </motion.div>
        </div>
      </div>

    </motion.div>
  );
};

export default Group;
