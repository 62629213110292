import { motion} from "framer-motion";
import { useState} from "react";
import "./TimeSelect.css";


const TimeSelect = ({ CS }) => {
    

    const [selectedTime, setSelectedTime] = useState('15 Seconds');

    const handleChange = (event) => {
        setSelectedTime(document.getElementById("timeSelector").value);
        CS();
    };
    const handleChange2 = (event) => {
      setSelectedTime(document.getElementById("timeSelector").value);
  };

  
  const dropIn = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="selectFull"
    >
        <h2 className="selectHead">Select Time</h2>
            <select className="select" id="timeSelector" onChange={handleChange2}>
                <option value="15 Seconds">15 Seconds</option>
                <option value="30 Seconds">30 Seconds</option>
                <option value="45 Seconds">45 Seconds</option>
                <option value="1 Minute">1 Minute</option>
                <option value="Unlimited">Unlimited</option>
            </select>

            <div className="done" onClick={handleChange}>Apply</div>
      
    </motion.div>
  );
};

export default TimeSelect;