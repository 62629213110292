import "./Recommended.css";


// Recommended plays component
const Recommended = ({ favoritePlays, SCP, playsData }) => {


  return (
    <div className="RecommendedPlays">
      {/* favorite plays are mapped into visual components */}
      {favoritePlays.map((i, index) => {
        return (
          <div className="RecommendedPlay" onClick={()=>SCP([playsData[favoritePlays[index]]])}>
            <div className="playPicRecommended">
              <img
                src={
                  playsData[favoritePlays[index]].id < 17
                    ? require(`${playsData[favoritePlays[index]].pic[0]}`)
                    : playsData[favoritePlays[index]].pic[0]
                }
                className="playPicInnerRecommended"
              />
            </div>
            <div className="playTitleRecommended">
              {playsData[favoritePlays[index]].title.substring(0, 17)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Recommended;
