import "./SeeByQuestion.css";
import { motion } from "framer-motion";

const SeeByQ = ({ close, titlen, correctn, ansn, pqn }) => {


  return (
    <div>
      <motion.div className="fullSBQ"></motion.div>

      <div className="topBarSBQ">
        <div onClick={close} className="backContainer-SBQ">
          <svg
            width="1.7vh"
            height="1.7vh"
            className="triBackD"
            viewBox="0 0 99 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z"
              fill="white"
            />
          </svg>
          <div className="backTxtSBQ">Back</div>
        </div>
        <div className="innerFlexTopBarSBQ">
          <div className="topBarTitleSBQ">Running Backs Basics</div>
        </div>
      </div>

      <div className="SBQOver">
        <div className="questionOverSBQ">
          <div className="SBQQuestion">{titlen}</div>
          <div className="playPicCapDQuiz">
            <img src={pqn} className="playPicDQuiz"></img>
          </div>
          <div className="answersCD">
            <div className={correctn == 0 ? "unAnswer2CD" : "answerCD" }>
              {" "}
              <div className="innerACD">{ansn[0]}</div>
            </div>
            <div className={correctn == 1 ? "unAnswer2CD" : "answerCD" }>
              {" "}
              <div className="innerACD">
                {ansn[1]}
              </div>
            </div>
          </div>

          <div className="answersCD">
            <div className={correctn == 2 ? "unAnswer2CD" : "answerCD" }>
              {" "}
              <div className="innerACD">{ansn[2]}</div>
            </div>
            <div className={correctn == 3 ? "unAnswer2CD" : "answerCD" }>
              {" "}
              <div className="innerACD">{ansn[3]}</div>
            </div>
          </div>
        </div>

        <div className="playersQuestion">
        <div className="PlayersHeadtxtD">Player Stats:</div>
        <div className="oFlexPlayersD">
         
            <div className="iiFlexPlayerD">
            <svg
                width="1.7vw"
                height=""
                viewBox="0 0 68 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.10938 64.1841L63.1843 4.10913"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M4.52344 3.40186L64.5984 63.4768"
                  stroke="#FF0000"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>
              <div className="playerResTxtD">Ben Hamilton</div>
            </div>
            <div className="iiFlexPlayerDC">
            <svg
                width="2.4vw"
                height=""
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="green" />
                <line
                  y1="-0.5"
                  x2="7.06771"
                  y2="-0.5"
                  transform="matrix(0.684031 0.729453 -0.709187 0.705021 6.21582 11.9111)"
                  stroke="white"
                />
                <line
                  y1="-0.5"
                  x2="14.3545"
                  y2="-0.5"
                  transform="matrix(0.625477 -0.780243 0.761995 0.647583 11.0503 17.0667)"
                  stroke="white"
                />
              </svg>

              <div className="playerResTxtDC">Griffin Speaks</div>
            </div>
          
        </div>

        </div>
        
        

        
      </div>



    </div>
  );
};

export default SeeByQ;
