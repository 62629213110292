import "./ProfList.css";
import { React } from "react";
import { motion } from "framer-motion";
import ProfDiv from "./ProfDiv";


// Group profile list
const ProfList = ({profiles, PlayerOrCoach, setPlayers, setCoaches}) => {



  return (
    <motion.div
      className="ProfList"
    >

      {/* Maps the profile order list */}
      {profiles.map((value, index) => (
        <ProfDiv PlayerOrCoach={PlayerOrCoach} setPlayers={setPlayers} setCoaches={setCoaches} indexToRemove={index} profile={value} />
      ))}

      
    </motion.div>
  );
};

export default ProfList;
