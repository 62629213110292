import { motion} from "framer-motion";
import "./FileSystem.css";

const FileSystem = ({
  setOpenNF,
  setSearch,
  back,
  keyn,
  folderComponent,
  resultList,
  SCP,
  setOpenDF,
}) => {


  
  return (
    <div className="playbookOver">
      <div className="playFolderHeadFlex">
        <div className="addSearch">
          <motion.svg
            width="2.5vw"
            height="2.5vw"
            viewBox="0 0 45 45"
            fill="none"
            className="r"
            whileTap={{ scale: 0.9 }}
            onClick={() => setOpenNF(true)}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="45" height="45" rx="15" fill="white" />
            <rect
              x="20.9453"
              y="12.1436"
              width="3.11642"
              height="20.7143"
              rx="1.55821"
              fill="black"
            />
            <rect
              width="19.4776"
              height="3.31429"
              rx="1.65714"
              transform="matrix(1 0 0 -1 12.7637 24.1589)"
              fill="black"
            />
          </motion.svg>

          <motion.svg
            width="2.5vw"
            height="2.5vw"
            viewBox="0 0 250 250"
            fill="none"
            className="r"
            onClick={() => setSearch(true)}
            whileTap={{ scale: 0.9 }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="250" height="250" rx="78" fill="white" />
            <path
              d="M164 107.5C164 138.176 139.351 163 109 163C78.6486 163 54 138.176 54 107.5C54 76.8239 78.6486 52 109 52C139.351 52 164 76.8239 164 107.5Z"
              stroke="black"
              stroke-width="6"
            />
            <path
              d="M198.749 201.97L144.503 146.499"
              stroke="black"
              stroke-width="12"
            />
          </motion.svg>
        </div>
        <h className="fileSystemGroupHead">Running Backs</h>

        <div className="pbSplit">
          <div className="foldersD">
            <svg
              width="1.5vw"
              height="1.5vw"
              onClick={back}
              viewBox="0 0 11 19"
              fill="none"
            >
              <path
                d="M9.5 1L1 9.5L9.5 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="folderCapD" key={keyn}>
              {folderComponent}
            </div>
          </div>
            <div className="pbBottomCapD">
              {resultList.map((item, index) => {
                    return (
                        <div
                          className="playD"
                          onClick={() => SCP([resultList[index]])}
                          key={index - resultList[index].id}
                        >
                          <div className="playPicBD">
                            <img
                              src={
                                resultList[index].id < 17
                                  ? require(`${resultList[index].pic[0]}`)
                                  : resultList[index].pic[0]
                              }
                              className="playPicInRFSD"
                            />
                          </div>
                          <div className="playTitlee">
                            {resultList[index].title}
                          </div>
                        </div>
                    );
                  }
                )}
            </div>
        </div>
        <div
          onClick={() => {
            setOpenDF(true);
          }}
          className="DeleteFolderD"
        >
          Delete Folder
        </div>
        <div className="viewAllFilesD" onClick={() => SCP(resultList)}>
          View All Files
        </div>
      </div>
    </div>
  );
};

export default FileSystem;
