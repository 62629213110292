import { motion } from "framer-motion";
import "./TimeSelect.css";


const TimeSelect = ({ CS, setSelectedTime }) => {
    



    const handleChange = (event) => {
        setSelectedTime(document.getElementById("timeSelector").value);
        CS();
    };
    const handleChange2 = (event) => {
      setSelectedTime(document.getElementById("timeSelector").value);
  };

  
  const dropIn = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="timeSelectFullDesktop"
    >
        <h2 className="selectHeadD">Select Time</h2>
            <select className="selectD" id="timeSelector" onChange={handleChange2}>
                <option value="15s">15 Seconds</option>
                <option value="30s">30 Seconds</option>
                <option value="45s">45 Seconds</option>
                <option value="1m">1 Minute</option>
                <option value="∞">Unlimited</option>
            </select>

            <div className="timeSelectApply" onClick={handleChange}>Apply</div>
      
    </motion.div>
  );
};

export default TimeSelect;