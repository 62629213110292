import { motion, AnimatePresence } from "framer-motion";
import "./NewFile.css";

const NewFile = ({
  titRef,
  descRef,
  setUP,
  upImgList,
  setIsTog,
  isTog,
  handleAddPlay,
  highestLevel
}) => {
  return (
    <div className="newFile">
      <div className="newFHead">New File</div>
      <div className="newFSub">Auburn University</div>
      <input
        className="inputNewFile"
        ref={titRef}
        id="in2"
        placeholder="Title"
      ></input>
      <input
        className="inputNewFile"
        ref={descRef}
        placeholder="Description"
      ></input>
      <div className="inputUploadNewFile" onClick={() => setUP(true)}>
        <input type="file" style={{ display: "none" }} />
        <div
          style={upImgList.length > 0 ? { color: "black" } : { color: "black" }}
        >
          Upload Image
        </div>
        <div className="arrowCircle">
          {upImgList.length > 0 && (
            <div className="outerCirc">{upImgList.length}</div>
          )}
          <svg
            width=".9vw"
            height=".9vw"
            className="arrowUpImg"
            viewBox="0 0 8 9"
            fill="none"
          >
            <path
              d="M7.34642 3.31001C7.54282 3.42055 7.70799 3.59041 7.82342 3.80059C7.93886 4.01076 8 4.25294 8 4.5C8 4.74706 7.93886 4.98924 7.82342 5.19941C7.70799 5.40959 7.54282 5.57945 7.34642 5.68999L1.71182 8.85945C1.53154 8.96089 1.33119 9.00882 1.12981 8.99867C0.928428 8.98852 0.732695 8.92063 0.561191 8.80145C0.389688 8.68228 0.248106 8.51576 0.149888 8.31772C0.0516693 8.11968 7.40371e-05 7.89668 0 7.6699L0 1.33099C-6.03694e-05 1.10413 0.0514368 0.881023 0.1496 0.682861C0.247762 0.484698 0.38933 0.318062 0.560855 0.19878C0.73238 0.0794989 0.928166 0.0115344 1.12961 0.00134347C1.33106 -0.00884752 1.53148 0.0390734 1.71182 0.140554L7.34642 3.31001Z"
              fill={upImgList.length > 0 ? "black" : "black"}
            />
          </svg>
        </div>
      </div>
      <div className="overallToggleFlex">
        <div className="starToggleTxt">Add to Recommended?</div>
        <div
          onClick={() => setIsTog(!isTog)}
          className={!isTog ? "outOval" : "outOval2"}
        >
          <div className={!isTog ? "inOval" : "inOval2"}></div>
        </div>
      </div>

      <div className="personnelTextD">Personnel:</div>

      <div className="newFilePersonnel">
        <motion.div
          style={{ border: "1.5px solid #5259FB" }}
          className="groupNewFile"
        >
          <div style={{ color: "#5259FB" }} className="groupTextNewFile">
            Running Backs
          </div>
        </motion.div>
        
      </div>

      <div className="outerATP">
      <motion.div
        whileTap={{ scale: 0.9 }}
        onClick={handleAddPlay}
        className="innerATP"
      >
        Add To {highestLevel.name}
      </motion.div>
      </div>

      
    </div>
  );
};

export default NewFile;
