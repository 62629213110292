import "./SearchMod.css";
import React, { useRef, useState } from "react";
import { motion } from "framer-motion";

const SearchMod = ({
  close,
  PVV,
  titles,
  groupPlays,
  playsData,
  pickPic,
  ifPic,
}) => {
  const [filteredResults, setFilteredResults] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const inputRef = useRef(null);

  function clear() {
    inputRef.current.value = "";
  }

  function PV(n) {
    if (ifPic) {
      pickPic(playsData[n].pic[0]);
    } else {
      PVV(n);
    }

    close();
  }

  const filterResults = (query) => {

    let newR = [];
    if (!query) {
      setFilteredResults([]);
    } else {
      const filtered = titles.filter((item, index) => {
        if (item.toLowerCase().includes(query.toLowerCase())) {
          newR.push(groupPlays[index]);
        }
        return item.toLowerCase().includes(query.toLowerCase());
      });
      setFilteredResults(filtered);
    }

    setResultList(newR);
  };

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterResults(query);
  };

  return (
    <div>
      <motion.div className="fullProfDD" onClick={close}></motion.div>

      <div className="SearchMod">
        <div className="search-boxD">
          <svg
            className="s-icon"
            width="10vw"
            height="4vw"
            viewBox="0 0 250 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M164 107.5C164 138.176 139.351 163 109 163C78.6486 163 54 138.176 54 107.5C54 76.8239 78.6486 52 109 52C139.351 52 164 76.8239 164 107.5Z"
              stroke="black"
              strokeWidth="6"
            />
            <path
              d="M198.749 201.97L144.503 146.499"
              stroke="black"
              strokeWidth="12"
            />
          </svg>
          <input
            className="pbSearchbarD"
            type="text"
            placeholder="Search"
            ref={inputRef}
            onChange={handleInputChange}
          ></input>
        </div>

        <div className="RFSBottomCapDD">
          {filteredResults.map((item, index) => {
            if ((index + 4) % 4 == 0) {
              if (filteredResults.length - index > 3) {
                return (
                  <div className="playRowRFSDD">
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index]].id < 17
                              ? require(`${
                                  playsData[resultList[index]].pic[0]
                                }`)
                              : playsData[resultList[index]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">{filteredResults[index]}</div>
                    </div>
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index + 1])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index + 1]].id < 17
                              ? require(`${
                                  playsData[resultList[index + 1]].pic[0]
                                }`)
                              : playsData[resultList[index + 1]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">
                        {filteredResults[index + 1]}
                      </div>
                    </div>
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index + 2])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index + 2]].id < 17
                              ? require(`${
                                  playsData[resultList[index + 2]].pic[0]
                                }`)
                              : playsData[resultList[index + 2]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">
                        {filteredResults[index + 2]}
                      </div>
                    </div>
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index + 3])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index + 3]].id < 17
                              ? require(`${
                                  playsData[resultList[index + 3]].pic[0]
                                }`)
                              : playsData[resultList[index + 3]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">
                        {filteredResults[index + 3]}
                      </div>
                    </div>
                  </div>
                );
              } else if (filteredResults.length - index > 2) {
                return (
                  <div className="playRowRFSDD">
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index]].id < 17
                              ? require(`${
                                  playsData[resultList[index]].pic[0]
                                }`)
                              : playsData[resultList[index]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">{filteredResults[index]}</div>
                    </div>
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index + 1])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index + 1]].id < 17
                              ? require(`${
                                  playsData[resultList[index + 1]].pic[0]
                                }`)
                              : playsData[resultList[index + 1]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">
                        {filteredResults[index + 1]}
                      </div>
                    </div>
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index + 2])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index + 2]].id < 17
                              ? require(`${
                                  playsData[resultList[index + 2]].pic[0]
                                }`)
                              : playsData[resultList[index + 2]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">
                        {filteredResults[index + 2]}
                      </div>
                    </div>
                  </div>
                );
              } else if (filteredResults.length - index > 1) {
                return (
                  <div className="playRowRFSDD">
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index]].id < 17
                              ? require(`${
                                  playsData[resultList[index]].pic[0]
                                }`)
                              : playsData[resultList[index]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">{filteredResults[index]}</div>
                    </div>
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index + 1])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index + 1]].id < 17
                              ? require(`${
                                  playsData[resultList[index + 1]].pic[0]
                                }`)
                              : playsData[resultList[index + 1]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">
                        {filteredResults[index + 1]}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="playRowRFSDD">
                    <div
                      className="playD"
                      onClick={() => PV(resultList[index])}
                    >
                      <div className="playPicBD">
                        <img
                          src={
                            playsData[resultList[index]].id < 17
                              ? require(`${
                                  playsData[resultList[index]].pic[0]
                                }`)
                              : playsData[resultList[index]].pic[0]
                          }
                          className="playPicInRFSD"
                        />
                      </div>
                      <div className="playTitlee">{filteredResults[index]}</div>
                    </div>
                  </div>
                );
              }
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchMod;
