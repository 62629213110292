import "./ProfListLeaderboard.css";
import { React } from "react";
import { motion } from "framer-motion";
import face from "../../../../Profiles/unknownProfile.jpg";

// Leaderboard profile list
const ProfListLeaderboard = (props) => {
  const names = ["Christian Shimko", "Ben Hamilton", "Griffin Speaks"];
  const pos = ["Wide Receiver #13", "Defensive Back #53", "Safety #39"];
  const scores = props.scores;

  // the order of the list
  const orderone = props.order;

  return (
    <motion.div className="profListLeaderboard">
      {/* Mapping the order of the profile list */}
      {orderone.map((index, value) => (
        <div className="profileLeaderboard">
          <div className="profPicLeaderboard">
            <img src={face} className="picLeaderboard" />
          </div>
          <div className="nameLeaderboard">
            <div>
              <div className="nameHeadLeaderboard">{names[index - 1]}</div>
              <div className="nameSubLeaderboard">{pos[index - 1]}</div>
            </div>

            <div className="timeLeaderboard"> {scores[index - 1]} </div>
          </div>
        </div>
      ))}
    </motion.div>
  );
};

export default ProfListLeaderboard;
