import { useState } from "react";
import {motion} from "framer-motion";
import Roster from "./Roster/Roster";
import Leaderboard from "./Leaderboard/LeaderboardD";
import Recommended from "./Recommended/Recommended";
import "./HomeSwitch.css"


// Right section of home component
const HomeSwitch = ({SCP, playsData, setOpenNewProfile, favoritePlays,players, coaches, setCoaches, setPlayers, profile }) => {



  // State to switch between Roster, Leaderboard, Recommended
    const [RLS, setRLS] = useState(0);

    function onRLS (i) {
        setRLS(i);
      }

      

    return (
        <div className="innerSPA2">
            
            {/* (Roster, Leaderboard, Recommended) header */}
            <div className="RLSFlexCapD">
              <div className="RLSitemCapD">
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  style={
                    RLS == 0
                      ? { backgroundColor: "#0072E2" }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => onRLS(0)}
                  className="RLSitemD"
                >
                  <svg
                    width="34"
                    height="46"
                    viewBox="0 0 34 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="1"
                      x2="0.5"
                      y2="45"
                      stroke={RLS == 0 ? "white" : "black"}
                    />
                    <line
                      x1="34"
                      y1="0.5"
                      y2="0.5"
                      stroke={RLS == 0 ? "white" : "black"}
                    />
                    <line
                      x1="33"
                      y1="45.5"
                      y2="45.5"
                      stroke={RLS == 0 ? "white" : "black"}
                    />
                    <line
                      x1="33.5"
                      x2="33.5"
                      y2="46"
                      stroke={RLS == 0 ? "white" : "black"}
                    />
                    <line
                      x1="9"
                      y1="5.5"
                      x2="26"
                      y2="5.5"
                      stroke={RLS == 0 ? "white" : "black"}
                    />
                    <rect
                      x="7.25"
                      y="20.25"
                      width="6.5"
                      height="6.5"
                      fill={RLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="7.25"
                      y="30.25"
                      width="6.5"
                      height="6.5"
                      fill={RLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="21.25"
                      y="10.25"
                      width="6.5"
                      height="6.5"
                      fill={RLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="7.25"
                      y="10.25"
                      width="6.5"
                      height="6.5"
                      fill={RLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="21.25"
                      y="20.25"
                      width="6.5"
                      height="6.5"
                      fill={RLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                    <rect
                      x="21.25"
                      y="30.25"
                      width="6.5"
                      height="6.5"
                      fill={RLS == 0 ? "white" : "black"}
                      stroke="black"
                      stroke-width="0.5"
                    />
                  </svg>
                  <div
                    className="RLStextD"
                    style={RLS == 0 ? { color: "white" } : { color: "black" }}
                  >
                    Roster
                  </div>
                </motion.div>
              </div>
              <div className="RLSitemCapD">
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  style={
                    RLS == 1
                      ? { backgroundColor: "#0072E2" }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => onRLS(1)}
                  className="RLSitemD"
                >
                  <svg
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.4375 2C29.4375 0.895432 30.3329 0 31.4375 0H34.6939H39.0016C40.1062 0 41.0016 0.89543 41.0016 2V38C41.0016 39.1046 40.1062 40 39.0016 40H31.4375C30.3329 40 29.4375 39.1046 29.4375 38V2Z"
                      fill={RLS == 1 ? "white" : "black"}
                    />
                    <path
                      d="M14.7168 10.9656C14.7168 9.86101 15.6122 8.96558 16.7168 8.96558H19.9732H24.2809C25.3855 8.96558 26.2809 9.86101 26.2809 10.9656V38.0001C26.2809 39.1046 25.3855 40.0001 24.2809 40.0001H16.7168C15.6122 40.0001 14.7168 39.1046 14.7168 38.0001V10.9656Z"
                      fill={RLS == 1 ? "white" : "black"}
                    />
                    <path
                      d="M0 20.6205C0 19.5159 0.895431 18.6205 2 18.6205H5.25642H9.56413C10.6687 18.6205 11.5641 19.5159 11.5641 20.6205V37.9998C11.5641 39.1044 10.6687 39.9998 9.56412 39.9998H2C0.895432 39.9998 0 39.1044 0 37.9998V20.6205Z"
                      fill={RLS == 1 ? "white" : "black"}
                    />
                  </svg>
                  <div
                    className="RLStextD"
                    style={RLS == 1 ? { color: "white" } : { color: "black" }}
                  >
                    Leaderboard
                  </div>
                </motion.div>
              </div>
              <div className="RLSitemCapD">
                <motion.div
                  whileTap={{ scale: 0.9 }}
                  style={
                    RLS == 2
                      ? { backgroundColor: "#0072E2" }
                      : { backgroundColor: "white" }
                  }
                  onClick={() => onRLS(2)}
                  className="RLSitemD"
                >
                  <svg
                    width="34"
                    height="46"
                    viewBox="0 0 34 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="1"
                      x2="0.5"
                      y2="45"
                      stroke={RLS == 2 ? "white" : "black"}
                    />
                    <line
                      x1="34"
                      y1="0.5"
                      y2="0.5"
                      stroke={RLS == 2 ? "white" : "black"}
                    />
                    <line
                      x1="33"
                      y1="45.5"
                      y2="45.5"
                      stroke={RLS == 2 ? "white" : "black"}
                    />
                    <line
                      x1="33.5"
                      x2="33.5"
                      y2="46"
                      stroke={RLS == 2 ? "white" : "black"}
                    />
                    <line
                      x1="9"
                      y1="5.5"
                      x2="24"
                      y2="5.5"
                      stroke={RLS == 2 ? "white" : "black"}
                    />
                    <path
                      d="M17 14L19.2451 20.9098H26.5106L20.6327 25.1803L22.8779 32.0902L17 27.8197L11.1221 32.0902L13.3673 25.1803L7.48944 20.9098H14.7549L17 14Z"
                      fill={RLS == 2 ? "white" : "black"}
                    />
                  </svg>
                  <div
                    className="RLStextD"
                    style={RLS == 2 ? { color: "white" } : { color: "black" }}
                  >
                    Recommended
                  </div>
                </motion.div>
              </div>
            </div>

            {RLS === 0 && <Roster players={players} profile={profile} coaches={coaches} setCoaches={setCoaches} setPlayers={setPlayers} setOpenNewProfile={setOpenNewProfile} />}
            {RLS === 1 && <Leaderboard />}
            {RLS === 2 && (
              <Recommended favoritePlays={favoritePlays} SCP={SCP} playsData={playsData}/>
              
            )}

          </div>
    )

};

export default HomeSwitch;