import "./CurrentQuizView.css";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";

const CurrQuizView = ({ close, quiz, setCurrentQuizzes, currentQuizzes }) => {
  
  ////////////////////!! Quiz deletion handler
  //will also close out of curr quiz view
  function deleteQuiz() {
    const newItems = currentQuizzes.filter((x, i) => x.id !== quiz.id);
    setCurrentQuizzes(newItems);
    close();
  }

  //state to toggle deletion modal
  const [del, setDel] = useState(false);

  //to determine question pic
  let pq = null;
  if (quiz.pics[0].substring(0, 2) == "./") {
    pq = require(`${quiz.pics[0]}`);
  } else {
    pq = quiz.pics[0];
  }
  //all question information
  const [question, setQuestion] = useState(quiz.questions[0]);
  const [correct, setCorrect] = useState(quiz.correctAnswers[0]);
  const [ans, setAns] = useState(quiz.possibleAnswers[0]);
  //Question pic
  const [QP, setQP] = useState(pq);

  //question #
  const [qNum, setQNUM] = useState(1);

  //move forward question by setting question #
  function goNext() {
    if (qNum < quiz.questions.length) {
      setQNUM(qNum + 1);
    }
  }
  //move back question by setting question #
  function goBack() {
    if (qNum > 1) {
      setQNUM(qNum - 1);
    }
  }

  //to set the question information to the according slide
  useEffect(() => {
    setQuestion(quiz.questions[qNum - 1]);
    setCorrect(quiz.correctAnswers[qNum - 1]);
    setAns(quiz.possibleAnswers[qNum - 1]);
    let p = null;
    if (quiz.pics[qNum - 1].substring(0, 2) == "./") {
      p = require(`${quiz.pics[qNum - 1]}`);
    } else {
      p = quiz.pics[qNum - 1];
    }
    setQP(p);
  }, [qNum]);

  return (
    <div>
      <motion.div className="fullCurrentQuiz"></motion.div>

      <div className="topBarCurrentQuiz">
        <div onClick={close} className="backContainer-CurrentQuiz">
          <svg
            width="1.7vh"
            height="1.7vh"
            className="triBackD"
            viewBox="0 0 99 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 54.5L98.25 0.373413L98.25 108.627L0 54.5Z"
              fill="white"
            />
          </svg>
          <div className="backTxtCurrentQuiz">Back</div>
        </div>
        <div className="innerFlexTopBarC">
          <div className="topBarTitleCurrentQuiz">{quiz.name}</div>
          <div className="topBarQuestions">{`${qNum}/${quiz.questions.length}`}</div>
        </div>
        <div className="flexColumnCurrentQuiz">
          <motion.div
            className="boxDelete"
            onClick={() => setDel(true)}
            whileTap={{ scale: 0.9 }}
          >
            <svg width="2.3vh" height="" viewBox="0 0 16 16" fill="none">
              <path
                d="M5.03125 2.23438H4.875C4.96094 2.23438 5.03125 2.17109 5.03125 2.09375V2.23438ZM5.03125 2.23438H10.9688V2.09375C10.9688 2.17109 11.0391 2.23438 11.125 2.23438H10.9688V3.5H12.375V2.09375C12.375 1.47324 11.8145 0.96875 11.125 0.96875H4.875C4.18555 0.96875 3.625 1.47324 3.625 2.09375V3.5H5.03125V2.23438ZM14.875 3.5H1.125C0.779297 3.5 0.5 3.75137 0.5 4.0625V4.625C0.5 4.70234 0.570312 4.76562 0.65625 4.76562H1.83594L2.31836 13.959C2.34961 14.5584 2.90039 15.0312 3.56641 15.0312H12.4336C13.1016 15.0312 13.6504 14.5602 13.6816 13.959L14.1641 4.76562H15.3438C15.4297 4.76562 15.5 4.70234 15.5 4.625V4.0625C15.5 3.75137 15.2207 3.5 14.875 3.5ZM12.2832 13.7656H3.7168L3.24414 4.76562H12.7559L12.2832 13.7656Z"
                fill="black"
              />
            </svg>
          </motion.div>
        </div>
      </div>

      <div className="currQuizOver">
        <div className="questionOver">
          <div className="currQuizQuestion">{question}</div>
          <div className="playPicCapDQuiz">
            <img src={QP} className="playPicDQuiz"></img>
          </div>
          <div className="answersCD">
            <div className={correct == 0 ? "unAnswer2CD" : "answerCD"}>
              {" "}
              <div className="innerACD">{ans[0]}</div>
            </div>
            <div className={correct == 1 ? "unAnswer2CD" : "answerCD"}>
              {" "}
              <div className="innerACD">{ans[1]}</div>
            </div>
          </div>

          <div className="answersCD">
            <div className={correct == 2 ? "unAnswer2CD" : "answerCD"}>
              {" "}
              <div className="innerACD">{ans[2]}</div>
            </div>
            <div className={correct == 3 ? "unAnswer2CD" : "answerCD"}>
              {" "}
              <div className="innerACD">{ans[3]}</div>
            </div>
          </div>
        </div>

        <div className="navFlex">
          <motion.div
            whileTap={{ scale: 0.9 }}
            onClick={goBack}
            className="arrowBox"
          >
            <svg
              width="3vw"
              height="3vw"
              viewBox="0 0 11 19"
              fill="none"
              style={{
                zIndex: "1000000000000000000000000000",
              }}
            >
              <path
                d="M9.5 1L1 9.5L9.5 18"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </motion.div>

          <motion.div
            whileTap={{ scale: 0.9 }}
            onClick={goNext}
            className="arrowBox"
          >
            <svg
              width="3vw"
              height="3vw"
              viewBox="0 0 11 19"
              fill="none"
              style={{
                transform: "rotate(-180deg)",
                zIndex: "1000000000000000000000000000",
              }}
            >
              <path
                d="M9.5 1L1 9.5L9.5 18"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </motion.div>
        </div>
      </div>

      <AnimatePresence initial={false} wait={true} onExitComplete={() => null}>
        {del && (
          <div onClick={() => setDel(false)} className="blackBackDelQuiz">
            <div className="delQuizBox" onClick={(e) => e.stopPropagation()}>
              <h className="delQuizBoxHead">Delete Quiz</h>
              <div>
                Are you sure you want to delete this Quiz? This action can not
                be undone.
              </div>
              <motion.div
                whileTap={{ scale: 0.9 }}
                onClick={deleteQuiz}
                className="DPdelQuiz"
              >
                Delete
              </motion.div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CurrQuizView;
